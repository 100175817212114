import { checkDiscountValidity } from './discountValidityCheck'

const createCartItem = (
  id,
  name,
  price,
  sku,
  image,
  quantity,
  minimum_order_quantity,
  permalink,
  product_type,
  dispatchInformation,
  discount,
  advanced_product_data,
  product_container_id,
  integration_configuration,
) => {
  //I check if the there is a discount on the server data
  //if there is I set discounted and discountedPrice
  //else I leave the false and null
  const { discounted, discountedPrice } = checkDiscountValidity(discount, price)

  return {
    id,
    name,
    price,
    sku,
    discountedPrice,
    discounted,
    image,
    quantity,
    minimum_order_quantity,
    permalink,
    product_type,
    dispatchInformation,
    advanced_product_data,
    product_container_id,
    integration_configuration,
  }
}

export default createCartItem
