import { CartItem } from '../../store/models'
import { shopSettingsVar } from '../../store/reactiveVars'

//* ===============================================================================================================================
//* ======================================= FORMAT PRICE ACCORDING TO LANGUAGE ====================================================
//* ===== format the price data, this function might need some changing once we have language and currency for each product =======
//* ===============================================================================================================================
export const priceFormatter = (price) => {
  if (!price) return ''

  const formatter = new Intl.NumberFormat('lt-LT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  })

  const formattedPrice = formatter.format(price)

  return formattedPrice
}

//* ==========================================================================================================================================
//* ================================================ Calculate subtotal given a list of cartItems ============================================
//* ==========================================================================================================================================
export const subtotalCartPrice = (cartItems: CartItem[], shopSettings) => {
  const { current_prices_with_vat, vat_percentage } = shopSettings
  let subtotal: number = null

  //loop through each cartItem and check if current prices with VAT
  //If they are always remove it and just then return the item
  let cartItemsPricesAndQuantity = cartItems.map((cartItem) => {
    if (current_prices_with_vat) {
      if (cartItem.discounted) {
        return {
          price: calculatePriceWithoutVat(
            cartItem.discountedPrice,
            vat_percentage,
          ),
          quantity: cartItem.quantity,
        }
      } else {
        return {
          price: calculatePriceWithoutVat(cartItem.price, vat_percentage),
          quantity: cartItem.quantity,
        }
      }
    } else {
      if (cartItem.discounted) {
        return { price: cartItem.discountedPrice, quantity: cartItem.quantity }
      } else {
        return { price: cartItem.price, quantity: cartItem.quantity }
      }
    }
  })

  cartItemsPricesAndQuantity.forEach((item) => {
    subtotal += item.price * item.quantity
  })

  return subtotal
}

//* ====================================================================================================================================
//* ================================================ Calculate discounted product container ============================================
//* ====================================================================================================================================
//These are for DISCOUNTS, which are always in percentage (not to confuse with coupons, which can be fixed or percentage)
export const getDiscountedProductPrice = (price, percentageDiscount) => {
  const { current_prices_with_vat, vat_percentage } = shopSettingsVar() as any
  let discountedPrice = null

  if (current_prices_with_vat) {
    let priceWithoutVat = calculatePriceWithoutVat(price, vat_percentage)
    let discountedPriceWithouVat =
      (priceWithoutVat * (100 - percentageDiscount)) / 100
    discountedPrice = calculatePriceWithVat(
      discountedPriceWithouVat,
      vat_percentage,
    )
  } else {
    discountedPrice = (price * (100 - percentageDiscount)) / 100
  }

  return discountedPrice
}

export const getPercentageDiscountAmount = (price, percentageDiscount) => {
  let discountedAmount = (price * percentageDiscount) / 100

  return discountedAmount
}

//* ====================================================================================================================================
//* ================================================ Calculate total_shipping_price ====================================================
//* ====================================================================================================================================
//* This function takes the price_for_box (ShippingMethods), the boxes (packing_information_list in single_product) and cartItem quantity
//* It will have two cases: Boxes.length > 0 or not
export const calculateShippingMethodPrice = (
  price_for_box,
  boxes,
  quantity,
) => {
  let total_shipping_price = 0

  if (boxes.length > 0) {
    // loop through all the boxes and for each we use a function to define
    // how many boxes are needed for the item
    boxes.map((box) => {
      let numberOfBoxes =
        quantity % box.number_of_products_that_fit_in_box === 0
          ? quantity / box.number_of_products_that_fit_in_box
          : Math.floor(quantity / box.number_of_products_that_fit_in_box) + 1

      total_shipping_price =
        total_shipping_price + numberOfBoxes * price_for_box
    })
  } else {
    total_shipping_price = price_for_box * quantity
  }

  return total_shipping_price
}

//* ====================================================================================================================================
//* ================================================ Calculate total_price_without_VAT =================================================
//* ====================================================================================================================================
export const calculatePriceWithoutVat = (price, VAT) => {
  const priceWithoutVat = price / ((100 + VAT) / 100)
  return priceWithoutVat
}

//* ==========================================================================================================================================
//* ====================================================== Calculate price WITH VAT ==========================================================
//* ==========================================================================================================================================
export const calculatePriceWithVat = (price, VAT) => {
  const amountToAdd = price * (VAT / 100)
  const priceWithVat = price + amountToAdd

  return priceWithVat
}

//* =================================================================================================================================================
//* ====================================================== Calculate VAT amount from price ==========================================================
//* =================================================================================================================================================
//This function gives back the amount that should be added on top of the given price without VAT (e.g. given the subtotal without VAT, we want to display how much VAT will be added on top to get the total price)
export const calculateVatAmount = (priceWithoutVat, VAT) => {
  return priceWithoutVat * (VAT / 100)
}

export const getPriceFromPriceCategories = (
  selectedAdvancedProductFabric,
  price_fabric_category,
) => {
  let currentPriceCategory
  let currentPriceCategoryArray

  if (selectedAdvancedProductFabric.combinationFabrics) {
    // getting all group numbers from selectedAdvancedProductFabric.combinationFabrics
    const groupNumbers = Object.values(
      selectedAdvancedProductFabric.combinationFabrics,
    ).map(
      (option: any) =>
        option.fabricGroupObject.form_price_fabric_category.group_number,
    )
    // filter unique group numbers by group_number
    const uniqueGroupNumbers = groupNumbers.filter(
      (value, index, self) => self.indexOf(value) === index,
    )
    // filter the price fabric category by group number
    currentPriceCategoryArray = price_fabric_category?.filter((category) =>
      uniqueGroupNumbers.includes(category.fabrice_price_category.group_number),
    )
  } else {
    currentPriceCategoryArray = price_fabric_category.filter(
      (category) =>
        category.fabrice_price_category.group_number ===
        selectedAdvancedProductFabric.fabricGroupObject
          .form_price_fabric_category.group_number,
    )
  }

  if (currentPriceCategoryArray?.length === 0) return null  //
  // find highest price
  currentPriceCategory = currentPriceCategoryArray?.reduce((prev, current) =>
    prev && prev.price > current.price ? prev : current,
  )
  return currentPriceCategory?.price
}
