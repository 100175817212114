import { ReactiveVar } from "@apollo/client";
import { Order, OrderItem } from "../store/models";

export const resetOrderVar = (orderVar: ReactiveVar<Order>) => {
  orderVar({
    address1: null,
    billing_address1: null,
    address2: null,
    billing_address2: null,
    city: null,
    billing_city: null,
    country: null,
    billing_country: null,
    email: null,
    firstName: null,
    lastName: null,
    order_items: [] as OrderItem[],
    phone: null,
    promo: false,
    state: null,
    postal_code: null,
    billing_postal_code: null,
    discount_applied: null,
    total_shipping_price: null,
    sub_total_price: null,
    total_price_without_VAT: null,
    total_price: null,
    buying_as_company: false,
    company_name: null,
    company_code: null,
    vat_code: null,
    zero_vat: false,
    is_lithuanian_company: null,
    coupon: null,
    metadata: undefined
  });
};
