import { useReactiveVar } from '@apollo/client'
import { Drawer, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import {
  fabricsCombinationDrawerStateVar,
  selectedAdvancedProductFabricCombinationVar,
  selectedAdvancedProductFabricVar,
} from '../../../../../store/reactiveVars'
import { useAppContext } from '../../../../../context/appContext'
import FabricCombinationDrawerCard from './FabricCombinationDrawerCard'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      combinationsDrawer: {
        width: 700,
        padding: theme.spacing(3, 5),
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      topDrawerDescription: {
        margin: theme.spacing(0, 0, 3, 0),
      },
      topDrawerContent: {
        margin: theme.spacing(2, 0, 0, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        marginBottom: theme.spacing(2),
      },
      closeIcon: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.6',
        },
      },
    }),
  { name: 'MuiColorsDrawerComponent' },
)

export default function FabricsCombinationsDrawer() {
  const { t } = useTranslation(['common'])
  const upholsterySetsString = t('upholstery-sets')
  const classes = useStyles() as any
  const { locale } = useAppContext()
  const fabricsCombinationDrawerState = useReactiveVar(
    fabricsCombinationDrawerStateVar,
  )

  const { fabricCombination } = useReactiveVar(
    selectedAdvancedProductFabricCombinationVar,
  )

  const onSelectedComponentChange = (selectedCombination) => {
    selectedAdvancedProductFabricCombinationVar({
      fabricCombination: selectedCombination,
    })
    fabricsCombinationDrawerStateVar({
      open: false,
      data: null,
    })
  }

  return (
    <>
      <Drawer
        classes={{
          paper: classes.combinationsDrawer,
        }}
        anchor="right"
        open={fabricsCombinationDrawerState.open}
        transitionDuration={{ enter: 600, exit: 200 }}
        onClose={() =>
          fabricsCombinationDrawerStateVar({
            open: false,
            data: null,
          })
        }
      >
        <div className={classes.topDrawerContent}>
          <Typography variant="h5">{upholsterySetsString}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() =>
              fabricsCombinationDrawerStateVar({
                open: false,
                data: null,
              })
            }
          />
        </div>

        <Grid container spacing={2} style={{ padding: 1 }}>
          {fabricsCombinationDrawerState.data?.map((combination, index) => {
            return (
              <Grid item xs={12} key={index}>
                <FabricCombinationDrawerCard
                  // This is some syntax bug but original id is passed as ID, however it should be product container ID from the top
                  combination={combination}
                  selected={fabricCombination?.id === combination.id}
                  onClick={onSelectedComponentChange}
                />
              </Grid>
            )
          })}
        </Grid>
      </Drawer>
    </>
  )
}
