import { useReactiveVar } from '@apollo/client'
import { Drawer, Grid, InputBase, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  componentsDrawerStateVar,
  fabricsDrawerStateVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricVar,
} from '../../../../../store/reactiveVars'
import { useAppContext } from '../../../../../context/appContext'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import AdditionalComponentDrawerCard from './AdditionalComponentDrawerCard'
import FabricDrawerCard from './FabricDrawerCard'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      fabricsDrawer: {
        width: 700,
        padding: theme.spacing(3, 5),
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      topDrawerDescription: {
        margin: theme.spacing(0, 0, 3, 0),
      },
      topDrawerContent: {
        margin: theme.spacing(2, 0, 0, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        marginBottom: theme.spacing(2),
      },
      input: {
        border: '1px solid #d3d3d3',
        padding: theme.spacing(1, 1, 1, 1),
        marginBottom: theme.spacing(2),
      },
      closeIcon: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.6',
        },
      },
    }),
  { name: 'MuiFabricsDrawerComponent' },
)

export default function FabricsDrawer() {
  const { t } = useTranslation(['common'])
  const classes = useStyles() as any
  const fabricsDrawerState = useReactiveVar(fabricsDrawerStateVar)
  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )
  const { locale } = useAppContext()
  const searchInputPlaceholder = t('search-inputPlaceholder')
  const [inputValue, setInputValue] = useState('')

  // filter components by search value
  const filteredComponents = useMemo(() => {
    if (!inputValue || inputValue.length < 2) {
      return fabricsDrawerState.data
    }
    return fabricsDrawerState.data
      ?.map((fabricGroup, index) => {
        // if fabric group name includes search value, return all fabrics
        const group_name = getProfileFromLanguage(
          fabricGroup?.fabric_group_profiles,
          locale,
        )?.name

        if (group_name?.toLowerCase().includes(inputValue.toLowerCase())) {
          return fabricGroup
        }

        return {
          ...fabricGroup,
          fabrics: fabricGroup.fabrics.filter(
            (fabric) =>
              fabric.code?.toLowerCase().includes(inputValue.toLowerCase()) ||
              fabric.color_name
                ?.toLowerCase()
                .includes(inputValue.toLowerCase()),
          ),
        }
      })
      .filter((fabricGroup) => fabricGroup.fabrics.length > 0)
  }, [fabricsDrawerState.data, inputValue])

  const optionInSelect = fabricsDrawerState?.option?.id
  const selectedFabricId =
    selectedAdvancedProductFabric?.combinationFabrics?.[optionInSelect]
      ?.fabricObject?.id

  const profile = getProfileFromLanguage(
    fabricsDrawerState.option?.fabricCombinationOptionProfiles,
    locale,
  )

  const selectorTitle = profile?.name ?? ''

  const onSelectedComponentChange = (fabric, fabricGroup) => {
    selectedAdvancedProductFabricVar({
      ...selectedAdvancedProductFabric,
      fabricGroupObject: fabricGroup,
      combinationFabrics: {
        ...selectedAdvancedProductFabricVar().combinationFabrics,
        [fabricsDrawerState.option.id]: {
          fabricGroupObject: fabricGroup,
          fabricObject: fabric,
          option: fabricsDrawerState.option,
        },
      },
    })
    fabricsDrawerStateVar({
      open: false,
      data: null,
      option: null,
    })
  }

  return (
    <>
      <Drawer
        classes={{
          paper: classes.fabricsDrawer,
        }}
        anchor="right"
        open={fabricsDrawerState.open}
        transitionDuration={{ enter: 600, exit: 200 }}
        onClose={() =>
          fabricsDrawerStateVar({
            open: false,
            data: null,
            option: null,
          })
        }
      >
        <div className={classes.topDrawerContent}>
          <Typography variant="h5"> {selectorTitle}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() =>
              fabricsDrawerStateVar({
                open: false,
                data: null,
                option: null,
              })
            }
          />
        </div>
        {/* <div className={classes.topDrawerDescription}>
          {' '}
          <Typography variant="caption">{groupProfile?.description}</Typography>
        </div> */}
        <InputBase
          className={classes.input}
          placeholder={`${searchInputPlaceholder}`}
          type="search"
          value={inputValue}
          //inputRef={searchInputRef}
          inputProps={{ 'aria-label': searchInputPlaceholder }}
          //onFocus={() => setOpen(true)}
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
        />
        <Grid container rowSpacing={1} columnSpacing={1}>
          {filteredComponents?.map((fabricGroup, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} key={'h' + fabricGroup.code}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="body2">
                        {
                          getProfileFromLanguage(
                            fabricGroup?.fabric_group_profiles,
                            locale,
                          )?.name
                        }
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {'€'.repeat(fabricGroup?.priceMultiplier ?? 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {fabricGroup.fabrics.map((color, j) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={3}
                      lg={2}
                      key={index + ':' + j + ':' + color.code}
                    >
                      <FabricDrawerCard
                        // This is some syntax bug but original id is passed as ID, however it should be product container ID from the top
                        fabricColor={color}
                        fabricGroup={fabricGroup}
                        selected={selectedFabricId === color.id}
                        onClick={onSelectedComponentChange}
                      />
                    </Grid>
                  )
                })}
              </React.Fragment>
            )
          })}
        </Grid>
      </Drawer>
    </>
  )
}
