import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'
import {
  Button,
  Divider,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { v5 as uuidv5 } from 'uuid'
import { useShopContext } from '../../../context'
import { AddressFormData } from '../../../store/models'
import { orderVar } from '../../../store/reactiveVars'
import { useAppContext } from '../../../context/appContext'
import { count } from 'console'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      pageTitle: {
        fontSize: 32,
        fontWeight: 500,
      },
      gridContainer: {
        margin: theme.spacing(2, 0, 2, -2),
        [theme.breakpoints.down('lg')]: {
          // maxWidth: '100%',
        },
        // marginLet: '36px',
      },
      input: {
        border: '1px solid #d3d3d3',
        padding: theme.spacing(1.3, 1),
      },
      inputLabel: {
        marginBottom: theme.spacing(0.5),
      },
      radioGroup: {
        display: 'flex',
        flexDirection: 'row',
      },
      button: {
        width: '40%',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
        },
      },
      error: {
        color: '#d9534f',
      },
      addressSelect: {
        width: '100%',
        margin: theme.spacing(2, 0, 0, 0),
      },
    }),
  { name: 'MuiDeliveryAddressFormComponent' },
)

const GET_ADDITIONAL_ADDRESS = gql`
  query GET_ADDITIONAL_ADDRESS($customerId: Int!) {
    getCustomer(id: $customerId) {
      id
      addresses {
        id
        address_1
        address_2
        city
        postal_code
        country
        state_region
        roles
      }
    }
  }
`
const MenuProps = {
  PaperProps: {
    style: {
      //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      maxWidth: '-webkit-fill-available',
    },
  },
}

export default function DeliveryAddressForm({ handleNextSubStep }) {
  const { t } = useTranslation(['common'])
  const fieldRequiredError = t('field-is-required')
  const nextStepButtonString = t('next-step-button')
  const formOneStepTwoHeader = t('form-one-step-two-header')
  const addressInputLabel = t('address-input-label')
  const address2InputLabel = t('address2-input-label')
  const cityInputLabel = t('city-input-label')
  const stateInputLabel = t('state-input-label')
  const zipInputLabel = t('zip-input-label')
  const countryInputLabel = t('country-input-label')
  const classes = useStyles() as any
  const shopContext = useShopContext()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useForm()
  const order = useReactiveVar(orderVar)

  ///====
  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken

  const disableInputFields = false // if b2bToken is present, disable the input fields
  const disableCountryInputFields = b2bToken ? true : false // if b2bToken is present, disable the input fields
  const [addressId, setAddressId] = useState(null)
  const [additionalAddress, setAdditionalAddress] = useState(null)

  const [fetchSearch, { data, loading, error, called }] = useLazyQuery(
    GET_ADDITIONAL_ADDRESS,
    {
      fetchPolicy: 'cache-and-network',
      context: { headers: { authorization: `Bearer ${b2bToken.userToken}` } },
    },
  )

  useEffect(() => {
    if (b2bToken) {
      setValue('address1', b2bToken.b2b_company_address.address_1 ?? '-')
      setValue('address2', b2bToken.b2b_company_address.address_2 ?? '-')
      setValue('city', b2bToken.b2b_company_address.city ?? '-')
      setValue('country', b2bToken.b2b_company_address.country ?? '-')
      setValue('postal_code', b2bToken.b2b_company_address.postal_code ?? '-')
      setValue('state', b2bToken.b2b_company_address.state_region ?? '-')

      fetchSearch({
        variables: {
          customerId: b2bToken.id,
        },
      })
      // query additional address fields
    }
  }, [])

  useEffect(() => {
    if (data) {
      const firstAddressId = data?.getCustomer?.addresses?.[0]?.id
      if (firstAddressId) {
        setAddressId(firstAddressId)
        handleAddressChange(firstAddressId)
      }
    }
  }, [data])

  //* =================================================================
  //* ====================== HANDLE ONSUBMIT ==========================
  //* =================================================================
  const onSubmit = (data) => {
    // if (data.address1 === undefined && b2bToken) {
    //   //-- We have the b2b case...

    //   handleNextSubStep()
    // } else {
    const billingData = {
      billing_address1: data.address1,
      billing_address2: data.address2,
      billing_city: data.city,
      billing_country: data.country ?? additionalAddress?.country,
      billing_postal_code: data.postal_code,
      country: data.country ?? additionalAddress?.country,
    }

    //* Spread an object containing all the data key value pairs and the new unique_generated

    orderVar({ ...order, ...data, ...billingData })
    handleNextSubStep()
    //   }
  }

  const handleAddressChange = (addressId) => {
    setAddressId(addressId)
    const selectedAddress = data?.getCustomer?.addresses.find(
      (address) => address.id === addressId,
    )

    setValue('address1', selectedAddress.address_1 ?? '-')
    setValue('address2', selectedAddress.address_2 ?? '-')
    setValue('city', selectedAddress.city ?? '-')
    setValue('country', selectedAddress.country ?? '-')
    setValue('postal_code', selectedAddress.postal_code ?? '-')
    setValue('state', selectedAddress.state_region ?? '-')

    setAdditionalAddress(selectedAddress)
  }

  // console.log('order :>> ', order)

  //TODO: IMPORTANT change the country to a list of defined countries, add state/region to the whole flow and add a textarea for "comment"
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        {formOneStepTwoHeader}
      </Typography>
      <Divider />
      {data?.getCustomer?.addresses.length > 0 && (
        <Select
          className={classes.addressSelect}
          value={addressId}
          onChange={(e) => handleAddressChange(e.target.value)}
          //input={<Input />}
          MenuProps={MenuProps}
          //inputProps={{ 'aria-label': sortByString }}
          renderValue={(selected) => {
            console.log('selected :>> ', selected)
            const selectedItem = data?.getCustomer?.addresses.find(
              (item) => item.id === selected,
            )
            return selectedItem
              ? `${selectedItem.roles} - ${selectedItem.address_1}`
              : ''
          }}
        >
          {data?.getCustomer?.addresses.map((address: any, index: number) => (
            <MenuItem value={address.id} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography noWrap>{address.roles}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    {address.address_1 ?? ''} {address.address_2 ?? ''}
                    {address.city ?? ''} {address.postal_code ?? ''}{' '}
                    {address.country ?? ''}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </Select>
      )}
      <Grid
        container
        className={classes.gridContainer}
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {addressInputLabel}
              {errors.address1 ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping address-line1"
            inputProps={{
              ...register('address1', { required: fieldRequiredError }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'address1' in order && order.address1 ? order.address1 : ''
            }
            disabled={disableInputFields}
          />
          {errors.address1 && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.address1.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {address2InputLabel}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping address-line2"
            inputProps={{
              ...register('address2'),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'address2' in order && order.address2 ? order.address2 : ''
            }
            disabled={disableInputFields}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {cityInputLabel}
              {errors.city ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            id="city"
            name="city"
            fullWidth
            autoComplete="shipping address-level2"
            inputProps={{
              ...register('city', { required: fieldRequiredError }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={'city' in order && order.city ? order.city : ''}
            disabled={disableInputFields}
          />
          {errors.city && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.city.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {stateInputLabel}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            inputProps={{ ...register('state') }}
            classes={{
              input: classes.input,
            }}
            defaultValue={'state' in order && order.state ? order.state : ''}
            disabled={disableInputFields}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {zipInputLabel}
              {errors.postal_code ? (
                <span className={classes.error}>*</span>
              ) : (
                `*`
              )}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping postal-code"
            inputProps={{
              ...register('postal_code', {
                required: fieldRequiredError,
              }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'postal_code' in order && order.postal_code
                ? order.postal_code
                : ''
            }
            disabled={disableInputFields}
          />
          {errors.postal_code && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.postal_code.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {countryInputLabel}
              {errors.country ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping country"
            inputProps={{
              ...register('country', { required: fieldRequiredError }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'country' in order && order.country ? order.country : ''
            }
            readOnly={disableCountryInputFields}
          />
          {errors.country && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.country.message}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Button variant="contained" type="submit" className={classes.button}>
        {nextStepButtonString}
      </Button>
    </form>
  )
}
