import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import {
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  List,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { green } from '@mui/material/colors'
import CheckIcon from '@mui/icons-material/Check'
import { Alert, Skeleton } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useShopContext } from '../../../context'
import { useCheckoutContext } from '../../../context/checkoutContext'
import { emptyCart } from '../../../operations/clientMutations/cartClient'
import { CREATE_ORDER } from '../../../operations/mutations'
import {
  GET_NEOPAY_WIDGET_URL,
  GET_SHOPSETTINGS_PAYMENT_METHODS,
} from '../../../operations/queries'
import { orderVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { resetOrderVar } from '../../../utilityFunctions/resetOrderVar'
import BillingAddressForm from './BillingAddressForm'
import { useAppContext } from '../../../context/appContext'
import { deleteCookie } from '../../../../lib/getUserIdFromCookie'

const useStyles = makeStyles(
  (theme: Theme) => ({
    formControl: {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    radioGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    paymentOptionContainer: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // minHeight: "6rem",
      marginBottom: '1rem',
    },
    paymentOptionSkeleton: {
      backgroundColor: '#f6f6f4',
      marginBottom: theme.spacing(1),
    },
    formControlBox: {
      backgroundColor: '#f6f6f4',
      width: '100%',
      display: 'flex',
      padding: theme.spacing(3, 2),
      gap: theme.spacing(2),
      margin: 0,
    },
    media: {
      // width: "8rem",
      width: '100%',
      backgroundSize: 'contain',
    },
    labelBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    buttonWrapper: {
      position: 'relative',
      width: '40%',
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginTop: theme.spacing(2),
      },
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      color: theme.palette.text.primary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    error: {
      color: '#d9534f',
    },
    changeBillingAddressBtn: {
      cursor: 'pointer',
      fontWeight: 500,
      textDecoration: 'underline',
    },
    noPaymentMethodsContainer: {
      height: theme.spacing(20),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
    contactButton: {
      width: '35%',
    },
  }),
  { name: 'MuiPaymentFormComponent' },
)

// const TLD = process.env.NEXT_PUBLIC_TLD

export default function PaymentForm({ handleNext }) {
  const { t } = useTranslation(['common'])
  const orderSlug = t('order-slug')
  const contactSlug = t('contact-slug')
  const chooseOneOptionString = t('please-choose-option')
  const formThreeHeader = t('form-three-header')
  const addressInputLabel = t('address-input-label')
  const address2InputLabel = t('address2-input-label')
  const cityInputLabel = t('city-input-label')
  const stateInputLabel = t('state-input-label')
  const zipInputLabel = t('zip-input-label')
  const countryInputLabel = t('country-input-label')
  const shippingAddressSummaryHeader = t('shipping-address-summary-header')
  const billingAddressSummaryHeader = t('billing-address-summary-header')
  const billingAddressChangeCta = t('billing-address-change-cta')
  const completeOrderCta = t('complete-order-cta')
  const contactUsString = t('contact-us')
  const noPaymentMethodsAvailableString = t('no-payment-methods-available')
  const classes = useStyles() as any
  const { locale } = useCheckoutContext()
  const [disableButton, setDisableButton] = useState(false)
  const [success, setSuccess] = useState(false)
  const [orderCreateError, setOrderCreateError] = useState('')
  //TODO: set this to true when the use clicks on "change" button
  const [changingBillingAddress, setChangingBillingAddress] = useState(false)
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })
  // const { SHOP_ID } = useShopContext()
  const shopContext = useShopContext()
  const SHOP_ID = shopContext.value.SHOP_ID
  const router = useRouter()

  // const hostname = window.location.hostname;
  const hostname = window.location.host

  //* =========================
  //* ==== React-hook-form ====
  //* =========================
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm()

  //* Lazy query to get the neopay widget redirect URL
  const [fetchWidgetUrl, queryResult] = useLazyQuery(GET_NEOPAY_WIDGET_URL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      window.location.replace(data.neopayWidget.widgetUrl)
      emptyCart()
      resetOrderVar(orderVar)
    },
  })

  //* =========================
  //* ====get shop settings====
  //* =========================
  const {
    data: shopSettingsData,
    loading: shopSettingsLoading,
    error: shopSettingsError,
  } = useQuery(GET_SHOPSETTINGS_PAYMENT_METHODS, {
    variables: {
      SHOP_ID,
      onlyVAT: false,
    },
    fetchPolicy: 'network-only',
  })

  //* =====================================================================
  //* Mutation to create one order, uses the option onCompleted and onError
  //* =====================================================================
  const [createOrder, { data: orderMutationData, called }] = useMutation(
    CREATE_ORDER,
    {
      onCompleted: (data) => {
        setSuccess(true)

        if (data.createNewOrder.project_id) {
          //if not null getNeoPayWidgetUrl to init Neopay payment
          fetchWidgetUrl({
            variables: {
              main_website_url: hostname,
              orderId: data.createNewOrder.id,
              TLD: locale.toUpperCase(),
            },
          })
        } else {
          //empty cart and redirect to order status page
          //TODO: IMPORTANT in the order status page, check if the payment method was not neopay and let them know that they received an email with all the details to complete the payment

          //Delete the Seession cookie, so the cart items are not reset upon refresh:
          deleteCookie('userSessionId')
          //-----
          emptyCart()
          resetOrderVar(orderVar)
          router.push(`/${orderSlug}/${data.createNewOrder.id}`)
        }
      },
      onError: (error) => {
        //TODO: handle this better, maybe show the snackbar and ask the user to try again later
        console.log('error ---> ', error)
        setOrderCreateError(error.message ?? 'Error!')
      },
    },
  )
  const order = useReactiveVar(orderVar)

  // console.log('order :>> ', order)
  ///====
  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken
  const price_multiplier = appContext.priceMultiplier
  const b2b_customer_discount = b2bToken
    ? b2bToken.b2b_customer_discount > 0
      ? b2bToken.b2b_customer_discount
      : null
    : null

  //* ========================================================================================================
  //* ========================================= HANDLE FORM SUBMIT ===========================================
  //* ========================================================================================================
  const onSubmit = (formData) => {
    // disable button to avoid any change of multiple submitting (maybe with slow connections??)
    setDisableButton(true)

    // deconstruct values contained in order reactive var
    const {
      address1,
      billing_address1,
      address2,
      billing_address2,
      city,
      billing_city,
      country,
      billing_country,
      email,
      firstName,
      lastName,
      order_items,
      phone,
      postal_code,
      billing_postal_code,
      total_shipping_price,
      sub_total_price,
      total_price_without_VAT,
      total_price,
      buying_as_company,
      company_name,
      company_code,
      vat_code,
      zero_vat,
      is_lithuanian_company,
      coupon,
      discount_applied,
      metadata,
    } = order

    console.log('order submited :>> ', order)

    // Radio only stores a string value, I need to parse it to an integer here
    let paymentMethodID = parseInt(formData.payment_method)

    //The discount_applied mutationVaribale doesn't allow setting it to null
    //so I want to check if it is null and if it is set it to 0
    const discount_appliedMutationVariable = discount_applied ?? 0

    console.log('order_items :>> ', order_items)
    const metadataConfigs = []
    order_items.forEach((item) => {
      metadataConfigs.push(item.integration_configuration)
      delete item.integration_configuration
    })
    console.log('metadataConfigs :>> ', metadataConfigs)

    // trigger the createOrder mutation using the values held by the order reactive constant
    // that by now should have all the information needed to fulfill an order
    createOrder({
      variables: {
        company_name,
        company_code,
        vat_code,
        zero_vat,
        buying_as_company,
        paymentMethodID,
        phoneNumber: phone,
        shipping_country: country,
        billing_country: billing_country,
        shipping_address_1: address1,
        billing_address_1: billing_address1,
        shipping_address_2: address2,
        billing_address_2: billing_address2,
        shipping_city: city,
        billing_city: billing_city,
        shipping_postal_code: postal_code,
        billing_postal_code: billing_postal_code,
        discount_applied: discount_appliedMutationVariable,
        sub_total_price,
        total_shipping_price,
        total_price_without_VAT,
        total_price,
        name: firstName,
        surname: lastName,
        email,
        order_items: order_items,
        coupon_code: coupon?.coupon_code ?? undefined,
        order_locale: locale,
        shopId: SHOP_ID,
        hostname,
        price_multiplier,
        b2b_customer_discount,
        metadata: {
          ...metadata,
          configurations: metadataConfigs,
        },
      },
    })
  }

  if (shopSettingsError) return <div>Something went wrong...</div>

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h5" gutterBottom>
          {formThreeHeader}
        </Typography>
        <Divider />

        {orderCreateError != '' && (
          <Alert severity="error">{orderCreateError}</Alert>
        )}

        <FormControl component="fieldset" className={classes.formControl}>
          <Controller
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
              if (!shopSettingsLoading) {
                return (
                  <RadioGroup
                    name="paymentOptionRadio"
                    className={classes.radioGroup}
                  >
                    {shopSettingsData.findFirstShopSetting.payment_methods
                      .length > 0 ? (
                      shopSettingsData.findFirstShopSetting.payment_methods.map(
                        (paymentMethod) => {
                          //if the payment method doesn't have a profile matching
                          //the current locale, we don't want to show it
                          const paymentMethodProfileIndex: number =
                            getProfileIndexFromLanguage(
                              paymentMethod.payment_method_profiles,
                              router.locale,
                            )

                          const paymentImage = paymentMethod.image
                            ? paymentMethod.image.src_md ??
                              paymentMethod.image.src ??
                              null
                            : null

                          if (
                            paymentMethod.payment_method_profiles[
                              paymentMethodProfileIndex
                            ] &&
                            paymentMethod.is_enabled &&
                            paymentMethod.enabled_languages.indexOf(
                              router.locale,
                            ) > -1
                          ) {
                            return (
                              <div
                                className={classes.paymentOptionContainer}
                                key={paymentMethod.id}
                              >
                                <FormControlLabel
                                  className={classes.formControlBox}
                                  label={
                                    <div style={{ display: 'flex' }}>
                                      <div className={classes.labelBox}>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                        >
                                          {
                                            paymentMethod
                                              .payment_method_profiles[
                                              paymentMethodProfileIndex
                                            ].title
                                          }
                                        </Typography>
                                        <Typography variant="body1">
                                          {
                                            paymentMethod
                                              .payment_method_profiles[
                                              paymentMethodProfileIndex
                                            ].description
                                          }
                                        </Typography>

                                        <Typography variant="caption">
                                          {
                                            paymentMethod
                                              .payment_method_profiles[
                                              paymentMethodProfileIndex
                                            ].instructions
                                          }
                                        </Typography>
                                      </div>
                                      {paymentMethod.image && (
                                        <CardMedia
                                          image={paymentMethod.image.src}
                                          className={classes.media}
                                        />
                                      )}
                                    </div>
                                  }
                                  control={
                                    <Radio
                                      inputRef={ref}
                                      disabled={disableButton}
                                      value={paymentMethod.id.toString()}
                                      onChange={onChange}
                                    />
                                  }
                                />
                              </div>
                            )
                          }
                        },
                      )
                    ) : (
                      <div className={classes.noPaymentMethodsContainer}>
                        <Typography
                          variant="body2"
                          gutterBottom
                          display="block"
                        >
                          {noPaymentMethodsAvailableString}
                        </Typography>
                        <Link href={`/${contactSlug}`}>
                          <Button
                            variant="contained"
                            type="submit"
                            className={classes.contactButton}
                          >
                            {contactUsString}
                          </Button>
                        </Link>
                      </div>
                    )}
                  </RadioGroup>
                )
              } else {
                return (
                  <Skeleton
                    height={118}
                    variant="rectangular"
                    className={classes.paymentOptionSkeleton}
                  />
                )
              }
            }}
            name="payment_method"
            defaultValue=""
            control={control}
            rules={{ required: chooseOneOptionString }}
          />
          {errors.payment_method && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.payment_method.message}
            </Typography>
          )}
        </FormControl>

        <Grid container>
          <Hidden lgDown>
            <Grid item xs={6}>
              <Typography variant="body2">
                {shippingAddressSummaryHeader}
              </Typography>

              <List component="ul" aria-label="Shipping Address">
                <Typography variant="caption" gutterBottom display="block">
                  {`${addressInputLabel}: ${order.address1}`}
                </Typography>
                <Typography variant="caption" gutterBottom display="block">
                  {`${address2InputLabel}: ${order.address2 === '' && '-'}`}
                </Typography>
                <Typography variant="caption" gutterBottom display="block">
                  {`${cityInputLabel}: ${order.city}`}
                </Typography>
                <Typography variant="caption" gutterBottom display="block">
                  {`${zipInputLabel}: ${order.postal_code}`}
                </Typography>
                <Typography variant="caption" gutterBottom display="block">
                  {`${countryInputLabel}: ${order.country}`}
                </Typography>
              </List>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2" component="span">
              {billingAddressSummaryHeader}
              {!changingBillingAddress && (
                <Typography
                  variant="caption"
                  component="span"
                  display="inline"
                  onClick={() => setChangingBillingAddress(true)}
                  className={classes.changeBillingAddressBtn}
                >
                  {` ${billingAddressChangeCta}`}
                </Typography>
              )}
            </Typography>

            <List component="ul" aria-label="Shipping Address">
              <Typography variant="caption" gutterBottom display="block">
                {`${addressInputLabel}: ${
                  order.billing_address1 ?? order.address1
                }`}
              </Typography>
              <Typography variant="caption" gutterBottom display="block">
                {`${address2InputLabel}:  ${
                  order.billing_address2 ?? order.address2
                }`}
              </Typography>
              <Typography variant="caption" gutterBottom display="block">
                {`${cityInputLabel}: ${order.billing_city ?? order.city}`}
              </Typography>
              <Typography variant="caption" gutterBottom display="block">
                {`${zipInputLabel}: ${
                  order.billing_postal_code ?? order.postal_code
                }`}
              </Typography>
              <Typography variant="caption" gutterBottom display="block">
                {`${countryInputLabel}: ${
                  order.billing_country ?? order.country
                }`}
              </Typography>
            </List>
          </Grid>
        </Grid>

        {!shopSettingsLoading &&
          (shopSettingsData.findFirstShopSetting.payment_methods.length > 0 ||
            shopSettingsData.findFirstShopSetting.default_payment_method) && (
            <div className={classes.buttonWrapper}>
              <Button
                variant="contained"
                type="submit"
                className={clsx(buttonClassname)}
                disabled={
                  changingBillingAddress || disableButton ? true : false
                }
              >
                {completeOrderCta}
              </Button>
              {called && !success && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {success && <CheckIcon className={classes.buttonProgress} />}
            </div>
          )}
      </form>

      {changingBillingAddress && (
        <BillingAddressForm
          changingBillingAddressState={{
            changingBillingAddress,
            setChangingBillingAddress,
          }}
        />
      )}
    </>
  )
}
