// ================ SOFA DRAWING STAGE UTILITIEs =================
//===============================================================

// Repel Two Groups
export function intersectionBetweenAllGroups(allGroupsOfGroups) {
  let isThereIntersection = false
  allGroupsOfGroups.forEach((groupOfGroups, i) => {
    const groupRect = getGroupOfGroupsRect(groupOfGroups)

    allGroupsOfGroups.forEach((secondaryGroupOfGroups, i2) => {
      // If index match, we checking self, so skip
      if (i === i2) {
        return
      }
      const secondaryRect = getGroupOfGroupsRect(secondaryGroupOfGroups)
      if (haveIntersection(groupRect, secondaryRect)) {
        // Repel two groups
        isThereIntersection = true
      }
    })
  })
  return isThereIntersection
}

// Mpve function
export function moveGroupOfGroups(arrayOfGroups, x, y) {
  // Looping each individual group
  arrayOfGroups.forEach((element) => {
    let currentPos = element.absolutePosition()
    currentPos.x = currentPos.x + x
    currentPos.y = currentPos.y + y
    element.absolutePosition(currentPos)
  })
}

export const getClientRect = (
  group,
  { padding = 0, roundValues = false, relativeTo = null } = {},
) => {
  const rect = group.getClientRect({
    skipStroke: true,
    skipShadow: true,
    relativeTo,
  })
  if (roundValues) {
    rect.x = Math.round(rect.x)
    rect.y = Math.round(rect.y)
    rect.width = Math.round(rect.width)
    rect.height = Math.round(rect.height)
  }
  rect.x += padding
  rect.y += padding
  rect.width -= padding * 2
  rect.height -= padding * 2
  return rect
}

// Takes in an arrayOfGroups [group1, group2. group3]
// Analyzes their coordidnates and returns a sorounding rectangle
export function getGroupOfGroupsRectWithScale(arrayOfGroups, scale) {
  // smallest and highest x define a rectangle
  let minX = 10000000000
  let maxX = 0
  let minY = 10000000000
  let maxY = 0

  // Not in pixels but in cm taken from what we add in admin
  // We need to calculate real Width and Height, as when we scale
  // Calcualtions with pixels add 0,5 +- margin of error
  // So we need to pass down accurate info
  // let realWidth = 0
  // let realHeight = 0
  // (NOT WORKING AS we get total height, but not actual height)

  // Looping each individual group
  arrayOfGroups.forEach((element) => {
    const currentRect = getClientRect(element, { roundValues: true })

    if (currentRect) {
      let currentMinX = Math.round(currentRect.x)
      let currentMaxX = Math.round(currentRect.x) + currentRect.width
      let currentMinY = Math.round(currentRect.y)
      let currentMaxY = Math.round(currentRect.y) + currentRect.height

      if (currentMinX < minX) {
        minX = currentMinX
      }
      if (currentMaxX > maxX) {
        maxX = currentMaxX
      }
      if (currentMinY < minY) {
        minY = currentMinY
      }
      if (currentMaxY > maxY) {
        maxY = currentMaxY
      }
    } else {
      // handle else...
      // console.log('We got ELSEEEEE')
    }
  })

  const width = Math.round((maxX - minX) / scale)
  const height = Math.round((maxY - minY) / scale)

  return {
    x: minX / scale,
    y: minY / scale,
    width: width,
    height: height,
    center: {
      x: minX / scale + width / 2,
      y: minY / scale + height / 2,
    },
    ranges: {
      minX,
      maxX,
      minY,
      maxY,
    },
  }
}

// Takes in an arrayOfGroups [group1, group2. group3]
// Analyzes their coordidnates and returns a sorounding rectangle
export function getGroupOfGroupsRect(arrayOfGroups) {
  // smallest and highest x define a rectangle
  let minX = 10000000000
  let maxX = 0
  let minY = 10000000000
  let maxY = 0

  // Looping each individual group
  arrayOfGroups.forEach((element) => {
    const currentRect = tansformTargetToRectWithRotation(element)

    let currentMinX = currentRect.x
    let currentMaxX = currentRect.x + currentRect.width
    let currentMinY = currentRect.y
    let currentMaxY = currentRect.y + currentRect.height

    if (currentMinX < minX) {
      minX = currentMinX
    }
    if (currentMaxX > maxX) {
      maxX = currentMaxX
    }
    if (currentMinY < minY) {
      minY = currentMinY
    }
    if (currentMaxY > maxY) {
      maxY = currentMaxY
    }
  })

  const width = maxX - minX
  const height = maxY - minY

  return {
    x: minX,
    y: minY,
    width: width,
    height: height,
    center: {
      x: minX + width / 2,
      y: minY + height / 2,
    },
    ranges: {
      minX,
      maxX,
      minY,
      maxY,
    },
  }
}

export function haveIntersection(r1, r2) {
  return !(
    r2.x > r1.x + r1.width - 1 ||
    r2.x + r2.width - 1 < r1.x ||
    r2.y > r1.y + r1.height - 1 ||
    r2.y + r2.height - 1 < r1.y
  )
}

// Takes in DropZone array ["right", "bottom"]
// Returns backrests -> ["left", "top"]
export function getBackRests(dropzoneArray) {
  const fullPsitionsList = ['left', 'right', 'top', 'bottom']
  return fullPsitionsList.filter((item) => !(dropzoneArray?.indexOf(item) > -1))
}

export const recursiveGroupMatchingFunction = (groupedGroups, called = 0) => {
  // console.log('groupedGroups.length :>> ', groupedGroups.length)
  // console.log('Recursive called :>> ', called)
  // console.log('groupedGroups :>> ', groupedGroups)
  // -----
  let finalGroupedGroups = [] as any
  let matchHappened = false
  //---
  // groupedGroups.forEach((group, i) => {
  loop1: for (let i = 0; i < groupedGroups.length; i++) {
    const group = groupedGroups[i]
    loop2: for (let i2 = 0; i2 < groupedGroups.length; i2++) {
      const secondaryGroup = groupedGroups[i2]
      // groupedGroups.forEach((secondaryGroup, i2) => {
      if (i === i2) {
        //skip
      } else {
        //----
        const groupIDS = group.map((x) => x.attrs.id)
        const secondaryGroupIDS = secondaryGroup.map((x) => x.attrs.id)
        // Returns array of duplicates betwen two arrays
        const duplicateCheck = secondaryGroupIDS.filter(
          (obj) => groupIDS.indexOf(obj) !== -1,
        )
        if (duplicateCheck.length > 0) {
          // console.log('Group i -->', i)
          // console.log('Group i2 -->', i2)
          // console.log('MATCH============= ')
          matchHappened = true
          // Merge two arrays and remove duplicates
          let newGroup = [...groupedGroups[i], ...groupedGroups[i2]]
          //@ts-ignore
          const uniqueGroup = [...new Set(newGroup)]
          // console.log('uniqueArray :>> ', uniqueGroup)

          let groupExists = false
          let existingGroupIndex = null
          // We push only if this group doesnt exist
          finalGroupedGroups.forEach((groupOfGroups, x) => {
            const arr = groupOfGroups.map((x) => x.attrs.id)
            const arr2 = uniqueGroup.map((x) => x.attrs.id)
            const duplicateCheck = arr2.filter((obj) => arr.indexOf(obj) !== -1)
            if (duplicateCheck.length === arr2.length) {
              groupExists = true
            }
          })
          if (!groupExists) {
            finalGroupedGroups.push(uniqueGroup)
          }
        } else {
          // finalGroupedGroups.push(groupedGroups[i])
          const groupToPush = groupedGroups[i]
          let groupExists = false
          let existingGroupIndex = null
          // We push only if this group doesnt exist
          finalGroupedGroups.forEach((groupOfGroups, x) => {
            const arr = groupOfGroups.map((item) => item.attrs.id)
            const arr2 = groupToPush.map((item) => item.attrs.id)
            const duplicateCheck = arr2.filter((obj) => arr.indexOf(obj) !== -1)
            if (duplicateCheck.length === arr2.length) {
              groupExists = true
              // existingGroupIndex = x
            } else if (
              duplicateCheck.length > 0 &&
              duplicateCheck.length < arr2.length
            ) {
              // 'One existing group is Bigger ===========
              groupExists = true
              existingGroupIndex = x
            }
          })
          if (!groupExists) {
            finalGroupedGroups.push(groupToPush)
          } else if (existingGroupIndex != null) {
            // Group exists
            // We should merge with
            const existingGroup = finalGroupedGroups[existingGroupIndex]
            if (existingGroup.length < groupToPush.length) {
              // console.log('Merge -> ', existingGroupIndex)
              finalGroupedGroups[existingGroupIndex] = groupToPush
            }
          }
        }
      }
    }
  }

  // Cases when its only one group
  if (groupedGroups.length <= 1) {
    finalGroupedGroups = groupedGroups
  }

  // --->  To protect from unexpected Crashes (maximum call stack exceeded)
  if (called > 30) {
    return finalGroupedGroups
  }

  if (matchHappened) {
    return recursiveGroupMatchingFunction(finalGroupedGroups, called + 1)
  } else {
    return finalGroupedGroups
  }
}

export const tansformTargetToRectWithRotationAndScale = (target, scale) => {
  // Do not mix with other targets...
  if (target) {
    if (target.attrs.name === 'sofa_shape_group') {
      var targetShape = target.findOne('.sofa_shape')

      if (targetShape) {
        var targetRect = targetShape.getClientRect()
        targetRect.width = target.attrs.width
        targetRect.height = target.attrs.height

        let currentRotation = target.findOne('.sofa_shape').attrs.rotation
        // console.log('targetRect :>> ', targetRect)
        let newWidth = target.attrs.width
        let newHeight = target.attrs.height
        let newX = targetRect.x / scale
        let newY = targetRect.y / scale
        switch (currentRotation) {
          case 0: {
            // We do nothing
            // console.log('Rotation 0')
            break
          }
          case 90: {
            // console.log('Rotation 90')
            newX = targetRect.x / scale - target.attrs.width
            break
          }
          case 180: {
            // We do nothing
            // might need to modify x, y
            // console.log('Rotation 180')
            newX = targetRect.x / scale - target.attrs.width
            newY = targetRect.y / scale - target.attrs.height
            break
          }
          case 270: {
            // console.log('Rotation 270')
            newX = targetRect.x / scale
            newY = targetRect.y / scale - target.attrs.height
            break
          }
        }
        return {
          x: newX,
          y: newY,
          width: newWidth,
          height: newHeight,
          rotation: currentRotation,
        }
      }
    }
  }
}

export const tansformTargetToRectWithRotation = (target) => {
  // Do not mix with other targets...
  if (target.attrs.name === 'sofa_shape_group') {
    var targetRect = target.findOne('.sofa_shape').getClientRect()
    targetRect.width = target.attrs.width
    targetRect.height = target.attrs.height

    let currentRotation = target.findOne('.sofa_shape').attrs.rotation
    // console.log('targetRect :>> ', targetRect)
    let newWidth = target.attrs.width
    let newHeight = target.attrs.height
    let newX = targetRect.x
    let newY = targetRect.y
    switch (currentRotation) {
      case 0: {
        // We do nothing
        // console.log('Rotation 0')
        break
      }
      case 90: {
        // console.log('Rotation 90')
        newX = targetRect.x - target.attrs.width
        break
      }
      case 180: {
        // We do nothing
        // might need to modify x, y
        // console.log('Rotation 180')
        newX = targetRect.x - target.attrs.width
        newY = targetRect.y - target.attrs.height
        break
      }
      case 270: {
        // console.log('Rotation 270')
        newX = targetRect.x
        newY = targetRect.y - target.attrs.height
        break
      }
    }
    return {
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight,
      rotation: currentRotation,
    }
  }
}

// Finds all seletected components that has armrestoverride parameter, moduleId specifies the specific sofa module that should be overriden
export const getArmrestOverides = (selectedComponents) => {
  const armOverideArr =
    selectedComponents
      .filter((component) => component?.dimensions?.armrest_width)
      .map((component) => ({
        armrestWidth: component.dimensions.armrest_width,
        moduleId: component.moduleId,
      })) ?? []

  return armOverideArr
}

//=============================================================

//=============================================================
//=============================================================
//=============================================================
// ===== WORKING IN GENERAL ===  FAILS AFTER 5 shapes
// // Returns arry of arrays which represent connected sofa_shapes
// const generateConnectedGroups = () => {
//   // Loop all shapes and group them if they are connected
//   let groupedGroups = [] as any
//   layer.find('.sofa_shape_group').forEach((group, i) => {
//     //-----
//     let currentGroup = [] as any
//     let currentGroupIndex = null
//     // Loop existing groops to either create a new one or modify an existing group
//     groupedGroups.forEach((previousGroup, i) => {
//       const previousGroupIds = previousGroup.map((item) => item.attrs.id)
//       const groupIndex = previousGroupIds.indexOf(group.attrs.id)
//       if (groupIndex > -1) {
//         // -- We belong to a previous group
//         currentGroup = previousGroup
//         currentGroupIndex = i
//       }
//     })

//     console.log('currentGroupIndex :>> ', currentGroupIndex)

//     if (currentGroup.length === 0) {
//       // -- we have a new group
//       currentGroup.push(group)
//     }

//     let groupRect = tansformTargetToRectWithRotation(group)
//     layer.find('.sofa_shape_group').forEach((groupSecondary, i2) => {
//       // Skip self
//       if (group.attrs.id === groupSecondary.attrs.id) {
//         return
//       }
//       // Running against other shapes
//       let againstGroupRect = tansformTargetToRectWithRotation(groupSecondary)

//       if (haveConnection(groupRect, againstGroupRect)) {
//         const currentGroupIds = currentGroup.map((item) => item.attrs.id)
//         if (currentGroupIds.indexOf(groupSecondary.attrs.id) > -1) {
//           //----
//         } else {
//           // -- We push only if you dont exist in this group
//           currentGroup.push(groupSecondary)
//         }
//       }
//     })

//     if (currentGroupIndex != null) {
//       groupedGroups[currentGroupIndex] = currentGroup
//     } else {
//       // ==== FINAL check here
//       // We have occaions when the calculation produces to groups of the same group
//       // Happens when the last shape in our looop is considered
//       // Does elements of this group already appear in existing groups?
//       let existingIndex = null
//       const currentGroupIds = currentGroup.map((item) => item.attrs.id)
//       groupedGroups.forEach((element, i) => {
//         element.forEach((item) => {
//           if (currentGroupIds.indexOf(item.attrs.id) > -1) {
//             //---
//             // console.log('Current group is matching with existing index ->', i)
//             // console.log('item.attrs.id :>> ', item.attrs.id)
//             existingIndex = i
//           }
//         })
//       })

//       if (existingIndex != null) {
//         groupedGroups[existingIndex] = currentGroup
//       } else {
//         groupedGroups.push(currentGroup)
//       }
//     }
//   })

//   // Scan the whole groupedGroups
//   // Check if other arrays contain same ID value
//   // If two arrays contain same ID, remove the array that has smaller length
//   // -- Almost working, but bad strategy....
//   // for (let i = 0; i < groupedGroups.length; i++) {
//   //   const checkingGroup = groupedGroups[i]
//   //   const checkingGroupIDS = groupedGroups[i].map((x) => x.attrs.id)

//   //   groupedGroups.forEach((group, i2) => {
//   //     const groupIDS = group.map((x) => x.attrs.id)
//   //     // Ignore self
//   //     if (i === i2) {
//   //       //skip
//   //     } else {
//   //       const output = checkingGroupIDS.filter(
//   //         (obj) => groupIDS.indexOf(obj) !== -1,
//   //       )

//   //       console.log('output :>> ', output)
//   //     }
//   //   })
//   // }

//   // console.log('groupedGroups :>> ', groupedGroups)
//   return groupedGroups
// }

// ==== WORKING BACKUP V1 (has an error with 7+more  shapes, due to bad logic)
// // Now we have separate groups, with touching elements, but not full group
// // so if 1) [1,2,3] ans 2) [2,3,4,5] --> They have common elements and should be -> [1,2,3,4,5]
// export const recursiveGroupMatchingFunction = (groupedGroups, called = 0) => {
//   // console.log('groupedGroups.length :>> ', groupedGroups.length)
//   // console.log('Recursive called :>> ', called)
//   // console.log('groupedGroups :>> ', groupedGroups)
//   // -----
//   let finalGroupedGroups = [] as any
//   let matchHappened = false
//   //---
//   groupedGroups.forEach((group, i) => {
//     groupedGroups.forEach((secondaryGroup, i2) => {
//       if (i === i2) {
//         //skip
//       } else {
//         //----
//         const groupIDS = group.map((x) => x.attrs.id)
//         const secondaryGroupIDS = secondaryGroup.map((x) => x.attrs.id)
//         // Returns array of duplicates betwen two arrays
//         const duplicateCheck = secondaryGroupIDS.filter(
//           (obj) => groupIDS.indexOf(obj) !== -1,
//         )
//         console.log('Group i -->', i)
//         console.log('duplicateCheck :>> ', duplicateCheck)

//         if (duplicateCheck.length > 0) {
//           // console.log('Group i -->', i)
//           // console.log('Group i2 -->', i2)
//           // console.log('MATCH============= ')

//           matchHappened = true
//           // Merge two arrays and remove duplicates
//           let newGroup = [...groupedGroups[i], ...groupedGroups[i2]]
//           //@ts-ignore
//           const uniqueGroup = [...new Set(newGroup)]
//           // console.log('uniqueArray :>> ', uniqueGroup)

//           let groupExists = false
//           let existingGroupIndex = null
//           // We push only if this group doesnt exist
//           finalGroupedGroups.forEach((groupOfGroups, x) => {
//             const arr = groupOfGroups.map((x) => x.attrs.id)
//             const arr2 = uniqueGroup.map((x) => x.attrs.id)
//             const duplicateCheck = arr2.filter((obj) => arr.indexOf(obj) !== -1)
//             if (duplicateCheck.length === arr2.length) {
//               groupExists = true
//             }
//           })
//           if (!groupExists) {
//             finalGroupedGroups.push(uniqueGroup)
//           }

//           // else {
//           //   // Replace the existing group
//           //   if (existingGroupIndex != null) {
//           //     finalGroupedGroups[existingGroupIndex] = uniqueGroup
//           //   }
//           // }

//         } else {
//           console.log('No match')
//           // console.log('Group i -->', i)
//           // finalGroupedGroups.push(groupedGroups[i])
//           const groupToPush = groupedGroups[i]
//           let groupExists = false
//           // We push only if this group doesnt exist
//           finalGroupedGroups.forEach((groupOfGroups) => {
//             const arr = groupOfGroups.map((x) => x.attrs.id)
//             const arr2 = groupToPush.map((x) => x.attrs.id)
//             const duplicateCheck = arr2.filter((obj) => arr.indexOf(obj) !== -1)
//             if (duplicateCheck.length === arr2.length) {
//               groupExists = true
//             }
//           })
//           if (!groupExists) {
//             finalGroupedGroups.push(groupToPush)
//           }

//         }
//       }

//     })
//   })

//   // ---> Breaks the code when there are 7-12 shapes, starts to loose shapes i a group
//   // ---- Additional filtering when there are many shapes
//   // finalGroupedGroups.forEach((groupOfGroups, i) => {
//   //   finalGroupedGroups.forEach((secondaryGroupOfGroups, i2) => {
//   //     if (i === i2) {
//   //       //skip
//   //     } else {
//   //       const arr = groupOfGroups.map((x) => x.attrs.id)
//   //       const arr2 = secondaryGroupOfGroups.map((x) => x.attrs.id)
//   //       const duplicateCheck = arr2.filter((obj) => arr.indexOf(obj) !== -1)
//   //       if (duplicateCheck.length > 0) {
//   //         // Duplicates
//   //         // Remove the smaller length duplicate
//   //         if (finalGroupedGroups[i]?.length > finalGroupedGroups[i2]?.length) {
//   //           // Remove i2
//   //           finalGroupedGroups.splice(i2, 1)
//   //           // } else if (
//   //           //   finalGroupedGroups[i2]?.length > finalGroupedGroups[i]?.length
//   //           // ) {
//   //           //   // Remove i
//   //           //   finalGroupedGroups.splice(i, 1)
//   //         } else {
//   //           // BREAKS THE CODE... AVOID THIS ELSE...
//   //           // Both equal remove i
//   //           // finalGroupedGroups.splice(i, 1)
//   //         }
//   //       }
//   //     }
//   //   })
//   // })

//   // Cases when its only one group
//   if (groupedGroups.length <= 1) {
//     finalGroupedGroups = groupedGroups
//   }

//   // --->  To protect from unexpected Crashes (maximum call stack exceeded)
//   if (called > 30) {
//     return finalGroupedGroups
//   }

//   if (matchHappened) {
//     return recursiveGroupMatchingFunction(finalGroupedGroups, called + 1)
//   } else {
//     return finalGroupedGroups
//   }
// }
