import { Box, Card, CardContent, Container, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
// Import css files
import 'slick-carousel/slick/slick.css'
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import { getProfileIndexFromLanguage } from '../../../../utilityFunctions/getProfile'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      sliderContainer: {
        position: 'relative',
        paddingRight: 0,
        // float: 'right',
        maxHeight: '500px',
        // overflow: 'visible',
        // paddingTop: theme.spacing(6),
        // paddingBottom: theme.spacing(6),
        // marginTop: theme.spacing(5),
        // [theme.breakpoints.down('md')]: {
        //   marginTop: theme.spacing(1),
        //   padding: theme.spacing(0, 0, 0, 2),
        // },

        '&::after': {
          content: '""',
          width: '100%',
          height: '35%',
          position: 'absolute',
          right: '0',
          bottom: '-30px',
          background:
            'linear-gradient(180deg, transparent 0%, rgba(247,249,250,1) 100%)',
          zIndex: 2,
        },
      },

      sliderContainerEnd: {
        '&::after': {
          display: 'none',
        },
      },

      card: {
        height: '100px',
        marginRight: '20px',

        [theme.breakpoints.down(1650)]: {
          height: '92px',
        },

        [theme.breakpoints.down(1500)]: {
          height: '85px',
        },

        [theme.breakpoints.down(1400)]: {
          height: '79px',
        },

        [theme.breakpoints.down(1350)]: {
          height: '72px',
        },
      },

      cardSelected: {
        borderLeft: '5px solid',
        borderColor: theme.palette.secondary.main,
        marginRight: '-10px',
        marginLeft: '20px',
      },

      cardContent: {
        height: '100%',
        padding: '10px !important',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      otherInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },

      title: {
        textTransform: 'uppercase',
        fontWeight: 500,
        lineHeight: '2em',
        fontSize: '16px',
      },

      shadesNumber: {
        background: '#e7e9ea',
        borderRadius: '28px',
        height: '28px',
        width: '28px',
        color: '#000',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',

        fontWeight: 500,
        lineHeight: '2em',
        // textAlign: 'center',
        // padding: '20px',
      },

      priceRangeString: {
        fontSize: '15px',
        color: '#8d8d8d',
      },

      nextArrow: {
        position: 'absolute',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        top: '-2rem',
        right: 'auto',
        left: '3rem',
        marginTop: '-20px',

        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '100%',
        background: '#e7e9ea',
        transform: 'rotate(90deg)',
        webkitTransform: 'rotate(90deg)',

        '&::before': {
          fontSize: '1rem',
          fontWeight: 700,
          content: '"next"',
          fontFamily: 'swiper-icons',
          color: '#000000',
        },
      },
      prevArrow: {
        position: 'absolute',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        top: '-2rem',
        right: 'auto',
        left: '0',
        marginTop: '-20px',

        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '100%',
        background: '#e7e9ea',
        transform: 'rotate(-90deg)',
        webkitTransform: 'rotate(-90deg)',

        '&::before': {
          fontSize: '1rem',
          fontWeight: 700,
          content: '"next"',
          fontFamily: 'swiper-icons',
          color: '#000000',
        },
      },
    }),
  { name: 'MuiFabricGroupSliderComponent' },
)

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy])

const FabricGroupsSlider = ({
  autoplay = false,
  className = null,
  items,
  selectedItem,
  setSelectedItem,
  ...rest
}: ProductSliderProps): JSX.Element => {
  const classes = useStyles() as any

  const [isLastSlide, setIsLastSlide] = useState(false)

  const numberOfSlides = items.length > 5 ? 5 : items.length

  useEffect(() => {
    if (items.length === numberOfSlides) {
      setIsLastSlide(true)
    }
  })

  const settings = {
    dots: false,
    infinite: false,
    vertical: true,
    verticalSwiping: true,
    speed: 500,
    slidesToShow: numberOfSlides,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow classes={classes} />,
    prevArrow: <SamplePrevArrow classes={classes} />,

    beforeChange: (current, next) => {
      if (next === items.length - numberOfSlides) {
        setIsLastSlide(true)
      } else {
        setIsLastSlide(false)
      }
    },
  }

  return (
    <Container
      maxWidth="xs"
      className={clsx(
        isLastSlide && classes.sliderContainerEnd,
        classes.sliderContainer,
      )}
    >
      <Slider key={items.length} {...settings}>
        {items.map((item) => {
          let selected = false
          if (selectedItem && selectedItem.id === item.id) {
            selected = true
          }

          return (
            <SwiperSlide key={item.id} tag="li" style={{ padding: '0px' }}>
              <ItemCard
                item={item}
                classes={classes}
                selected={selected}
                onItemChange={setSelectedItem}
              />
            </SwiperSlide>
          )
        })}
      </Slider>
    </Container>
  )
}

const ItemCard = ({ item, classes, selected, onItemChange }) => {
  // ----
  const { locale } = useRouter()
  const handleChange = (e) => {
    // Trying to change selected item
    onItemChange(item)
  }

  const fabricGroupProfileIndex: number = getProfileIndexFromLanguage(
    item?.fabric_group_profiles,
    locale,
  )

  const name =
    item?.fabric_group_profiles[fabricGroupProfileIndex]?.name ??
    'Not translated'

  const shadesNumber = item.fabrics.length

  return (
    <Card
      elevation={1}
      square={true}
      className={clsx(selected && classes.cardSelected, classes.card)}
      onClick={(e) => handleChange(e)}
    >
      <CardContent className={classes.cardContent}>
        <Box className={classes.title}>{name}</Box>
        <Box className={classes.otherInfo}>
          <span className={classes.shadesNumber}> {shadesNumber} </span>
          {/* <p className={classes.priceRangeString}>{priceRangeString}</p> */}
        </Box>
      </CardContent>
    </Card>
  )
}

const SampleNextArrow = (props) => {
  const { className, style, onClick, classes } = props
  return (
    <div
      className={clsx(className, classes.nextArrow)}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick, classes } = props
  return (
    <div
      className={clsx(className, classes.prevArrow)}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

export default FabricGroupsSlider
