import { createContext, useContext, useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import router, { useRouter } from 'next/router'
import NotFound from '../components/Modules/NotFound'
import {
  EmbedConfiguratorContext,
  useEmbedContext,
} from './embedConfiguratorContext'

const AppContext = createContext(null)

export function AppContextWrapper({ locale, shopSettings, children }) {
  //----
  const router = useRouter()
  const embedContext = useEmbedContext()
  const isEmbed = embedContext?.isEmbed

  //------------------------------
  //==== B2B REDIRECT SETTINGS =====
  //-------------------------------
  const isB2BEnabled = shopSettings.b2b_enabled
  const B2BhidePricesForAllProducts =
    shopSettings.b2b_hide_prices_for_all_products
  const redirectToLogin = shopSettings.b2b_redirect_to_login

  // console.log('redirectToLogin :>> ', redirectToLogin)

  //------------------------------
  //==== B2B AUTH LOGIC HERE =====
  //-------------------------------
  const [b2bToken, setB2BToken] = useState(null)
  const [b2bShowOnlySelectedProducts, setB2bShowOnlySelectedProducts] =
    useState(false)
  const [priceListId, setPriceListId] = useState(null)
  const [palettes, setPalettes] = useState([])

  useEffect(() => {
    //--- We check for the tokken
    const b2bToken = localStorage.getItem('furnisystems_token')
    if (b2bToken) {
      //--- We ahave token...
      console.log('We have a logged in user with token.')
      // const b2bUserObject = de constructToken(b2bToken)

      // Decode the JWT
      const decodedToken: any = jwtDecode(b2bToken)
      setB2BToken(decodedToken)
      setB2bShowOnlySelectedProducts(decodedToken?.b2b_only_selected_products)

      setPriceListId(decodedToken?.price_listId)
      setPalettes(decodedToken?.fabric_palettes)

      console.log('decodedToken :>> ', decodedToken)
    } else {
      //---
      console.log('No B2B Token...')
      setB2BToken(null)

      if (redirectToLogin) {
        router.push('/login') // Redirect to login page, users with no b2b token
      }
    }
  }, [])

  // console.log('App context firing.')

  //-- We decide if we hide or not
  const pricesDisabled =
    (isB2BEnabled && B2BhidePricesForAllProducts && !b2bToken) ||
    (isEmbed && embedContext?.queryParams?.showPrices == false)
      ? true
      : false

  //IS THERE A SITEWIDE PRICE MULTIPLIER FROM LANGUAGE SETTINGS?
  const currentLanguage = shopSettings.enabled_languages.filter(
    (item) => item.language === locale,
  )[0]

  const priceMultiplier = currentLanguage?.price_multiplier
    ? currentLanguage.price_multiplier
    : 1

  return (
    <AppContext.Provider
      value={{
        locale,
        shopSettings,
        b2bToken,
        pricesDisabled,
        priceMultiplier,
        b2bShowOnlySelectedProducts,
        priceListId,
        palettes,
      }}
    >
      {isB2BEnabled &&
      redirectToLogin &&
      !b2bToken &&
      router.pathname != '/login' ? (
        <span></span>
      ) : (
        <>{children}</>
      )}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}
