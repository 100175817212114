// import { cache } from "../../../store/cache";

import { getCookie } from '../../../../lib/getUserIdFromCookie'
import { cartItemSaveMutation } from '../../../../lib/userSessionTracking'
import { CartItem, ProductType } from '../../../store/models'
import { cartItemsVar, orderVar } from '../../../store/reactiveVars'
import createCartItem from '../../../utilityFunctions/createCartItem'

interface CartItemConstructor {
  id: number
  name: string
  price: number
  sku: string | null
  image: string
  quantity: number | null
  minimum_order_quantity: number | null
  permalink: string
  product_type: ProductType
  dispatchInformation: object
  discount: object
  advanced_product_data: object | null
  product_container_id: number
  integration_configuration: object | null
  prices_per_module: object | null
}

//! ADD TO LOCALSTORAGE CARTITEMS
export const addToCart = (
  constructor: CartItemConstructor,
  { snackbar, setSnackbar },
  translatedData,
) => {
  const { nonCumulativeCouponWhenAddingProduct } = translatedData
  const newCartItem: CartItem = createCartItem(
    constructor.id,
    constructor.name,
    constructor.price,
    constructor.sku,
    constructor.image,
    constructor.quantity,
    constructor.minimum_order_quantity,
    constructor.permalink,
    constructor.product_type,
    constructor.dispatchInformation,
    constructor.discount,
    constructor.advanced_product_data ?? null,
    constructor.product_container_id,
    constructor.integration_configuration,
  )

  console.log('In add to cart...')

  const userCookie = getCookie('userSessionId')
  cartItemSaveMutation(userCookie, newCartItem)
  // console.log('userCookie ->', userCookie)

  // console.log('newCartItem ->', newCartItem)
  //TODO:
  //before doing anything we need to check if the user previously
  //went to checkout and added a coupon
  //handle the case where the products he's adding has a discount
  //but the coupon can't be applied on top of disocunted products.
  const order = orderVar()

  //* =================================================================================
  //* get the cartItems from local storage, if there's none, cartItems will be === null
  //* =================================================================================
  // const cartItems: CartItem[] = JSON.parse(localStorage.getItem('cartItems'))
  const cartItems: CartItem[] = cartItemsVar()

  if (cartItems) {
    let isSame = false

    //* ====================================================================================================================
    //* - If cartItems already has items, we loop through each item and check if its ID is equal to the ID of the incomig item.
    //* - If it is we add +1 to quantity and set local storage and the cartItemsVar to the updated cartItems array.
    //* - else if cartItems is null, we just set localStorage and the cartItemsVar to an array with the newCartItem only.
    //* ====================================================================================================================
    cartItems.forEach((cartItem, i) => {
      // Some Advanced products will have qunatity null (e.g. sofas)
      if (cartItem.id === newCartItem.id && newCartItem.quantity != null) {
        isSame = true
        cartItems.splice(i, 1, {
          ...cartItem,
          quantity: cartItem.quantity + 1,
        })
        return
      }
    })

    // console.log('newCartItem ->', newCartItem)
    !isSame && cartItems.push(newCartItem)

    // localStorage.setItem('cartItems', JSON.stringify([...cartItems]))
    cartItemsVar([...cartItems])
  } else {
    // console.log('Pushing else ->', newCartItem)
    // localStorage.setItem('cartItems', JSON.stringify([newCartItem]))
    cartItemsVar([newCartItem])
  }
}

//! REMOVE FROM LOCAL STORAGE CARTITEMS
export const removeFromCart = (cartItemID) => {
  // localStorage.getItem('cartItems')
  // const cartItems: CartItem[] = JSON.parse(localStorage.getItem('cartItems'))

  const cartItems: CartItem[] = cartItemsVar()

  let cartItemToUpdate
  if (cartItems) {
    cartItems.forEach((cartItem, i) => {
      if (cartItem.id === cartItemID) {
        cartItemToUpdate = cartItem
        cartItems.splice(i, 1)
      }
    })
  } else {
    return
  }

  if (cartItemToUpdate) {
    const userCookie = getCookie('userSessionId')
    cartItemSaveMutation(userCookie, cartItemToUpdate, true)
  }

  // localStorage.setItem('cartItems', JSON.stringify([...cartItems]))
  cartItemsVar([...cartItems])
}

//! INCREMENT QUANTITY CART ITEM
export const incrementQuantity = ({
  cartItemID,
  followProductStock = false,
  productQuantity = 0,
  minimum_order_quantity = null,
}) => {
  // const cartItems: CartItem[] = JSON.parse(localStorage.getItem('cartItems'))
  const cartItems: CartItem[] = cartItemsVar()

  // ==== Handle stop incrementing aftter product quantity is reached...
  // console.log("incrementing product quantity");
  // console.log("followProductStock :>> ", followProductStock);
  // console.log("productQuantity :>> ", productQuantity);
  // console.log(
  //   'minimum_order_quantity incrementQuantity :>> ',
  //   minimum_order_quantity,
  // )

  let cartItemToUpdate

  if (cartItems) {
    cartItems.forEach((cartItem, i) => {
      if (cartItem.id === cartItemID) {
        const newCartItem = {
          ...cartItem,
          quantity: followProductStock
            ? cartItem.quantity >= productQuantity
              ? productQuantity
              : cartItem.quantity + (minimum_order_quantity ?? 1)
            : cartItem.quantity + 1,
        }

        cartItems.splice(i, 1, newCartItem)
        cartItemToUpdate = newCartItem
      }
    })
  } else {
    return
  }

  // -- INCREMENT UPDATE ACTIONS TO BACKEND
  if (cartItemToUpdate) {
    const userCookie = getCookie('userSessionId')
    cartItemSaveMutation(userCookie, cartItemToUpdate)
  }

  // localStorage.setItem('cartItems', JSON.stringify([...cartItems]))
  cartItemsVar([...cartItems])
}

//! DECREMENT QUANTITY CART ITEM
export const decrementQuantity = ({
  cartItemID,
  minimum_order_quantity = null,
}) => {
  // const cartItems: CartItem[] = JSON.parse(localStorage.getItem('cartItems'))
  const cartItems: CartItem[] = cartItemsVar()

  const amountToDecrease = minimum_order_quantity ? minimum_order_quantity : 1

  let cartItemToUpdate

  if (cartItems) {
    cartItems.forEach((cartItem, i) => {
      if (cartItem.id === cartItemID) {
        const newCartItem = {
          ...cartItem,
          quantity:
            cartItem.quantity === 1
              ? 1
              : cartItem.quantity === minimum_order_quantity
              ? minimum_order_quantity
              : cartItem.quantity - minimum_order_quantity >
                minimum_order_quantity
              ? cartItem.quantity - (minimum_order_quantity ?? 1)
              : minimum_order_quantity ?? 1,
        }

        cartItems.splice(i, 1, newCartItem)

        cartItemToUpdate = newCartItem
      }
    })
  } else {
    return
  }

  // -- DECREMENT UPDATE ACTIONS TO BACKEND
  if (cartItemToUpdate) {
    const userCookie = getCookie('userSessionId')
    cartItemSaveMutation(userCookie, cartItemToUpdate)
  }

  // localStorage.setItem('cartItems', JSON.stringify([...cartItems]))
  cartItemsVar([...cartItems])
}

//! EMPTY CART
export const emptyCart = () => {
  // localStorage.removeItem('cartItems')
  cartItemsVar([] as CartItem[])
}
