import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close' // Material-UI Close Icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle' // Material-UI Check Circle Icon
import { useTranslation } from 'react-i18next'
import { getCookie } from '../../../../lib/getUserIdFromCookie'
import { useMutation, gql } from '@apollo/client'
import { LinearProgress, Typography } from '@mui/material'
import dynamic from 'next/dynamic'
import { SAVE_CUSTOMER_TO_SESSION } from './TrackingUserModal'
import { useAppContext } from '../../../context/appContext'

const SofaImagesToDataURI = dynamic(
  () => import('../../DesktopElements/CartPageElements/SofaImagesToDataURI'),
  {
    ssr: false,
  },
)

interface TrackingUserModalProps {
  open: boolean
  onClose: () => void
  cartItems: any
}

const SendCartToEmailModal: React.FC<TrackingUserModalProps> = ({
  open,
  onClose,
  cartItems,
}) => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [emailError, setEmailError] = useState(false) // Added emailError state
  const [loading, setLoading] = useState(false)
  const { locale } = useAppContext()
  //---- Translations
  const { t } = useTranslation(['common'])
  const modalTitle = t('save-cart-modal-title')
  const modalSubtitle = t('save-cart-modal-subtitle')
  const emailContact = t('email-contact')
  const submitLabel = t('save-cart-send-pdf-button')

  const successString = t('success')
  const closeButtonString = t('close')

  const handleClose = () => {
    setEmail('')
    setSuccess(false)
    onClose()
    setLoading(false)
  }

  // Define the mutation hook
  const [saveCustomerToSessionMutation, { loading: loadingCustomerToSession }] =
    useMutation(SAVE_CUSTOMER_TO_SESSION)

  const handleSave = async () => {
    // Verify if the email is valid
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

    if (isValidEmail) {
      // Call the GraphQL mutation with the required arguments
      await saveCustomerToSessionMutation({
        variables: {
          cookie: getCookie('userSessionId'), // Replace with your cookie name
          email,
          phoneNumber: null,
          type: 'SAVE_CART_TO_EMAIL',
          language: locale,
        },
      })

      setLoading(true)
      setEmailError(false) // Reset emailError state
    } else {
      // Display an error for an invalid email
      setEmailError(true)
    }
  }

  const onComplete = () => {
    console.log('onComplete')
    setSuccess(true)
  }

  const onError = () => {
    console.log('onError')
    setSuccess(true)
    setLoading(false)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {modalTitle}
        </Typography>
        <IconButton
          aria-label="Close"
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {loading && (
          <SofaImagesToDataURI
            cartItems={cartItems}
            onComplete={onComplete}
            onError={onError}
            email={email}
          />
        )}
        {success ? (
          <div>
            <CheckCircleIcon sx={{ fontSize: 50, color: 'green' }} />
            <p style={{ margin: 10 }}>{successString}</p>
            <Button variant="contained" color="primary" onClick={handleClose}>
              {closeButtonString}
            </Button>
          </div>
        ) : (
          <div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {modalSubtitle}
            </Typography>
            <TextField
              label={emailContact + '*'}
              fullWidth
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError} // Apply error styling if emailError is true
              helperText={emailError ? 'Invalid email format' : ''}
            />
            {loading && <LinearProgress />}
            <div style={{ height: 24 }}></div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              {submitLabel}
            </Button>
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default SendCartToEmailModal
