import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { motion, useViewportScroll } from 'framer-motion'
import React, { useEffect } from 'react'
import { Media } from '../../../lib/artsyFresnel/Media'
// * HEADER ELEMENTS
import {
  CartDrawer,
  CategoriesNavigation,
  HeaderTopBanner,
  TopHeader,
  WishlistDrawer,
} from '../DesktopElements/HeaderElements'
import { ResponsiveTopHeader } from '../ResponsiveElements/ResponsiveHeaderElements'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      appBar: {
        backgroundColor: '#fff',
        height: 'fit-content',
        width: '100%',
        zIndex: 1200,
      },
      appBarStatic: {
        backgroundColor: '#fff',
        height: 'fit-content',
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 5,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      logoBox: {
        flexGrow: 1,
        textAlign: 'center',
        padding: 10,
      },
      logo: {
        maxWidth: 250,
        cursor: 'pointer',
      },
    }),
  { name: 'MuiHeaderComponent' },
)

const Header = ({ locale, data }) => {
  const classes = useStyles() as any
  const [showBanner, setShowBanner] = React.useState(true)
  const { scrollY } = useViewportScroll()

  // --- This has to be turned on for dynamic data loading
  // const { loading, error, data } = useQuery(GET_PARENT_CATEGORIES, {
  //   variables: { language: locale },
  // });

  // useEffect(() => {
  //   function updateScroll(scrollValue) {
  //     if (scrollValue > 150) {
  //       setShowBanner(false)
  //     } else {
  //       setShowBanner(true)
  //     }
  //   }

  //   const unsubscribeY = scrollY.onChange(updateScroll)

  //   return () => {
  //     unsubscribeY()
  //   }
  // }, [scrollY, setShowBanner])

  const headerVariants = {
    show: {
      y: 0,
      transition: {
        duration: 0.2,
      },
    },
    hide: {
      y: -40,
      transition: {
        duration: 0.4,
      },
    },
  }

  return (
    <>
      <Media greaterThan="md">
        <motion.div
          className={classes.appBarStatic}
          animate={showBanner ? 'show' : 'hide'}
          variants={headerVariants}
          id="header"
        >
          <HeaderTopBanner />

          <TopHeader />

          <CategoriesNavigation
            borderBottomOnScroll={showBanner}
            data={data}
            locale={locale}
          />
        </motion.div>
      </Media>

      <Media lessThan="lg">
        <ResponsiveTopHeader locale={locale} data={data} />
      </Media>

      <CartDrawer />
      <WishlistDrawer />
    </>
  )
}

export default Header
