import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { ProductPrice } from '../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        // width: "9rem",
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "baseline",
        padding: theme.spacing(2, 0),
        // backgroundColor: theme.palette.background.default,
      },
      cardActionAreaContainer: {
        filter: 'brightness(0.97) invert(0.001)',
        backgroundColor: '#fefefe',
      },
      media: {
        height: '11rem',
        backgroundSize: 'contain',

        [theme.breakpoints.down('lg')]: {
          height: '13rem',
        },
      },
      productName: {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        // textAlign: "center",
      },
    }),
  { name: 'MuiSearchDropdownCardComponent' },
)

export default function SearchDropdownCard({
  productID,
  setState,
  withPrice = false,
  productContainer,
}) {
  const classes = useStyles() as any
  const { locale } = useRouter()
  // const { data, loading, error } = useQuery(SEARCH_DROPDOWN_PRODUCT_CARD, {
  //   variables: {
  //     productID,
  //   },
  //   // fetchPolicy: "cache-and-network",
  // });
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')

  // if (loading) return null;
  // if (error)
  //   return (
  //     <div>Something went wrong {console.log("error", error.message)}</div>
  //   );

  let productProfileIndex
  let productPrice
  let productImage
  let productName
  let permalink

  if (productContainer.type === 'SIMPLE_PRODUCT') {
    // --- Simple product case
    productProfileIndex = getProfileIndexFromLanguage(
      productContainer.single_product.product_profiles,
      locale,
    )
    productPrice = productContainer.single_product.price
    productImage = productContainer.single_product.images[0].src
    productName =
      productContainer.single_product.product_profiles[productProfileIndex].name
    permalink =
      productContainer.single_product.product_profiles[productProfileIndex]
        .meta_information.permalink
  } else if (productContainer.type === 'ADVANCED_PRODUCT') {
    // --- Advanced product case
    productProfileIndex = getProfileIndexFromLanguage(
      productContainer.advanced_product.advanced_product_profiles,
      locale,
    )
    productPrice = productContainer.advanced_product.price
    productImage = productContainer.advanced_product.images[0]?.src
    productName =
      productContainer.advanced_product.advanced_product_profiles[
        productProfileIndex
      ].name
    permalink =
      productContainer.advanced_product.advanced_product_profiles[
        productProfileIndex
      ].meta_information.permalink
  } else {
    // --- Unhandled product type
    return null
  }

  return (
    <Grid item xs={6} sm={4}>
      <Card
        elevation={0}
        square={true}
        className={classes.card}
        onClick={() => {
          setState(false)
        }}
      >
        <div className={classes.cardActionAreaContainer}>
          <CardActionArea>
            <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
              <CardMedia className={classes.media} image={productImage} />
            </Link>
          </CardActionArea>
        </div>
        <CardContent className={classes.cardContent}>
          <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
            <span className="cursorPointer">
              <Typography
                gutterBottom
                variant="caption"
                component="h2"
                className={classes.productName}
              >
                {productName}
              </Typography>
            </span>
          </Link>
          {withPrice && (
            <Typography variant="body2">
              <ProductPrice price={productPrice} />
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}
