import { useQuery, useReactiveVar } from '@apollo/client'
import { CardMedia, Divider, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { addToCart } from '../../../operations/clientMutations/cartClient'
import { removeFromWishlist } from '../../../operations/clientMutations/wishlistClient'
import { GET_PRODUCT_IN_CART } from '../../../operations/queries/headerQueries'
import { WishlistItem } from '../../../store/models'
import {
  cartStateVar,
  wishlistDrawerVar,
  wishlistItemsVar,
} from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import {
  localDataUpdate,
  localStorageKeys,
} from '../../../utilityFunctions/localDataUpdate'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { useSnackbarContext } from '../Utils'
import { ProductPrice } from '../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme) => ({
    cartItem: {
      display: 'grid',
      gridTemplateColumns: '1fr 1.5fr',
      height: '10rem',
    },
    cartItem__imageWrapper: {
      padding: '0.5rem',
      cursor: 'pointer',
    },
    media: {
      height: '100%',
      backgroundSize: 'contain',
    },
    cartItem__info: {
      padding: '0.5rem 0 0.5rem 0.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cartItem__actions: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cartItem__quantitySelector: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '40%',
    },
    icon: {
      cursor: 'pointer',
    },
    addToCartButton: {
      display: 'inline-block',
    },
    remove: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    pricesContainer: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    discountedPrice: {
      textDecoration: 'line-through',
    },
    skeleton: {},
    productName: { cursor: 'pointer' },
  }),
  { name: 'MuiWishlistItemComponent' },
)

export default function WishlistItemComponent({ wishlistItem }) {
  //----
  // console.log('wishlistItem :>> ', wishlistItem)
  const productType = wishlistItem.product_type

  if (productType === 'ADVANCED_PRODUCT') {
    return <WishlistItemComponentAdvancedProduct wishlistItem={wishlistItem} />
  } else if (productType === 'SIMPLE_PRODUCT') {
    return <WishlistItemComponentSimpleProduct wishlistItem={wishlistItem} />
  } else {
    return <p>Unhandled product type</p>
  }
}

const WishlistItemComponentSimpleProduct = ({ wishlistItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const nonCumulativeCouponWhenAddingProduct = t(
    'non-cumulative-coupon-when-adding-product',
  )
  const removeWishlistItem = t('remove-wishlist-item')
  const classes = useStyles() as any
  // Since cartItems won't be statically built
  //I can use useRouter() to get the locale
  const { locale } = useRouter()
  const { snackbar, setSnackbar } = useSnackbarContext()
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discounted, setDiscounted] = useState(false)

  //* =================================================================================
  //* ==================  query to get latest cartItems info ==========================
  //* =================================================================================
  const { data, loading, error, called } = useQuery(GET_PRODUCT_IN_CART, {
    variables: {
      itemID: wishlistItem.id,
    },
    //On completed we will have the data and we want to update
    //all the static data in the reactive var and localstorage
    onCompleted: (data) => {
      localDataUpdate(
        data,
        wishlistItem,
        wishlistItems,
        wishlistItemsVar,
        localStorageKeys.wishlistItems,
        setDiscounted,
        setDiscountedPrice,
        locale,
      )
    },
    onError: (error) => {
      console.log('Error in wishlistItem.tsx')
    },
  })

  //TODO: make better UI for loading state
  if (loading) return <Skeleton height="10rem" className={classes.skeleton} />

  //* ======================================================
  //* Once the data has come (we are after the "loading" check)
  //* we want to run the check and get this item's profile
  //* ======================================================
  const productProfileIndex: number = getProfileIndexFromLanguage(
    data.findUniqueProductContainer.single_product?.product_profiles,
    locale,
  )

  //* ============================================================
  //* Here we know if the profile exists or not, if it does
  //* this component can be displayed in the current locale
  //* else this item will be removed from client data by the
  //* above useEffect
  //* =============================================================
  if (
    data.findUniqueProductContainer.single_product?.product_profiles[
      productProfileIndex
    ]
  ) {
    const id = data.findUniqueProductContainer.id
    const permalink =
      data.findUniqueProductContainer.single_product?.product_profiles[
        productProfileIndex
      ].meta_information.permalink
    const image = data.findUniqueProductContainer.single_product.images[0].src
    const name =
      data.findUniqueProductContainer.single_product?.product_profiles[
        productProfileIndex
      ].name
    const price = data.findUniqueProductContainer.single_product.price
    const minimum_order_quantity =
      data.findUniqueProductContainer.single_product.minimum_order_quantity ??
      null

    return (
      <div>
        <div className={classes.cartItem}>
          <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
            <div className={classes.cartItem__imageWrapper}>
              <CardMedia image={wishlistItem.image} className={classes.media} />
            </div>
          </Link>
          <div className={classes.cartItem__info}>
            <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
              <div className={classes.productName}>
                <Typography variant="subtitle1">{name}</Typography>
              </div>
            </Link>
            <div className={classes.pricesContainer}>
              <Typography
                variant={discounted ? 'body1' : 'body2'}
                component="p"
                className={clsx(discounted && classes.discountedPrice)}
              >
                <ProductPrice price={price} />
              </Typography>
              <Typography variant="subtitle1" component="p">
                {' '}
                {discountedPrice && <ProductPrice price={discountedPrice} />}
              </Typography>
            </div>
            <div className={classes.cartItem__actions}>
              <Typography
                variant="subtitle2"
                onClick={() => {
                  removeFromWishlist(id)
                }}
                className={classes.remove}
              >
                {removeWishlistItem}
              </Typography>
              <span className="cursorPointer">
                <AddShoppingCartIcon
                  onClick={() => {
                    addToCart(
                      {
                        ...(wishlistItem as WishlistItem),
                        quantity: 1,
                        discount: data.findUniqueProductContainer.discount,
                        advanced_product_data: null,
                        product_container_id:
                          data.findUniqueProductContainer.id,
                        minimum_order_quantity,
                        integration_configuration: null,
                        prices_per_module: null,
                      },
                      { snackbar, setSnackbar },
                      { nonCumulativeCouponWhenAddingProduct },
                    )
                    wishlistDrawerVar(false)
                    cartStateVar(true)
                    removeFromWishlist(wishlistItem.id)
                  }}
                />
              </span>
            </div>
          </div>
        </div>
        <Divider />
      </div>
    )
  } else {
    return null
  }
}

const WishlistItemComponentAdvancedProduct = ({ wishlistItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const nonCumulativeCouponWhenAddingProduct = t(
    'non-cumulative-coupon-when-adding-product',
  )
  const removeWishlistItem = t('remove-wishlist-item')
  const classes = useStyles() as any
  // Since cartItems won't be statically built
  //I can use useRouter() to get the locale
  const { locale } = useRouter()
  const { snackbar, setSnackbar } = useSnackbarContext()
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discounted, setDiscounted] = useState(false)

  //* ============================================================
  //* Here we know if the profile exists or not, if it does
  //* this component can be displayed in the current locale
  //* else this item will be removed from client data by the
  //* above useEffect
  //* =============================================================

  const id = wishlistItem.id
  const permalink = wishlistItem.permalink
  const image = wishlistItem.image
  const name = wishlistItem.name
  // const price = data.findUniqueProductContainer.single_product.price

  return (
    <div>
      <div className={classes.cartItem}>
        <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
          <div className={classes.cartItem__imageWrapper}>
            <CardMedia image={wishlistItem.image} className={classes.media} />
          </div>
        </Link>
        <div className={classes.cartItem__info}>
          <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
            <div className={classes.productName}>
              <Typography variant="subtitle1">{name}</Typography>
            </div>
          </Link>
          {/* <div className={classes.pricesContainer}>
              <Typography
                variant={discounted ? 'body1' : 'body2'}
                component="p"
                className={clsx(discounted && classes.discountedPrice)}
              >
               <ProductPrice price={price} />
              </Typography>
              <Typography variant="subtitle1" component="p">
                {" "}{discountedPrice && <ProductPrice price={discountedPrice} />}
              </Typography>
            </div> */}
          <div className={classes.cartItem__actions}>
            <Typography
              variant="subtitle2"
              onClick={() => {
                removeFromWishlist(id)
              }}
              className={classes.remove}
            >
              {removeWishlistItem}
            </Typography>
            {/* <span className="cursorPointer">
                <AddShoppingCartIcon
                  onClick={() => {
                    addToCart(
                      {
                        ...(wishlistItem as WishlistItem),
                        quantity: 1,
                        discount: data.findUniqueProductContainer.discount,
                        advanced_product_data: null,
                        product_container_id: data.findUniqueProductContainer.id,
                      },
                      { snackbar, setSnackbar },
                      { nonCumulativeCouponWhenAddingProduct },
                    )
                    wishlistDrawerVar(false)
                    cartStateVar(true)
                    removeFromWishlist(wishlistItem.id)
                  }}
                />
              </span> */}
          </div>
        </div>
      </div>
      <Divider />
    </div>
  )
}
