import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { Grid, LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import { Pagination } from '@mui/material'
import { PaginationItem } from '@mui/material'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useCategoryContext } from '../../../context/pagenumberContext'
import {
  GET_FILTERED_PRODUCTS,
  GET_MIN_MAX_DIMENSIONS_STATIC,
  GET_MIN_MAX_PRICE_STATIC,
} from '../../../operations/queries'
import {
  getCategoriesQueryVariable,
  getDimensionsQueryVariable,
  getFrameColorQueryVariable,
  getMainColorQueryVariable,
  getProductTypeQueryVariable,
  getStylesQueryVariable,
} from '../../../operations/queries/variablesConstructors'
import {
  categoriesFilterVar,
  colorsFilterVar,
  frameColorsFilterVar,
  heightFilterVar,
  lengthFilterVar,
  priceFilterVar,
  productTypeFilterVar,
  sortByVar,
  stylesFilterVar,
  widthFilterVar,
} from '../../../store/reactiveVars'
import { handleClearAllFilters } from '../../../utilityFunctions/filtersUtils'
import { FilterComponent, LoadMoreButton } from '../Utils'
import NoDataFound from '../Utils/NoDataFound'
import CategoryProductCard from './CategoryProductCard'
import DynamicCategoryProductCard from './DynamicCategoryProductCard'
import { useAppContext } from '../../../context/appContext'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: theme.spacing(4),
      },
      gridItem: {
        height: 'fit-content',
      },
      productCard: {
        padding: 10,
      },
      loadMoreBtn: {
        marginTop: '3rem',
        width: '10rem',
      },
      linearProgress: {
        marginTop: theme.spacing(2),
      },
      linearProgressColor: {
        backgroundColor: theme.palette.text.secondary,
      },
      noResult: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(10, 0),
        alignItems: 'center',
      },
      noResultHeader: {
        marginBottom: theme.spacing(15),
      },
      clearFiltersText: {
        marginBottom: theme.spacing(3),
      },
      noResultClearFiltersButton: {
        width: '10rem',
      },
      paginationRoot: {
        marginTop: theme.spacing(8),
      },
    }),
  { name: 'MuiCategoryProductListComponent' },
)

export default function CategoryProductList({
  staticData,
  productsCount,
  permalink,
  numberOfPages,
  pageNumber,
  pageSlug,
  fullPermalink,
  categoryId = null,
  // productsMinMaxPriceStatic,
  // productsMinMaxDimensions,
}) {
  const { t, i18n } = useTranslation(['common'])
  const categorySlug = t('category-slug')
  const router = useRouter()
  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken

  const classes = useStyles() as any
  const colorsFilter = useReactiveVar(colorsFilterVar)
  const frameColorsFilter = useReactiveVar(frameColorsFilterVar)
  const priceFilter = useReactiveVar(priceFilterVar)
  const widthFilter = useReactiveVar(widthFilterVar)
  const heightFilter = useReactiveVar(heightFilterVar)
  const lengthFilter = useReactiveVar(lengthFilterVar)

  const categoriesFilter = useReactiveVar(categoriesFilterVar)
  const stylesFilter = useReactiveVar(stylesFilterVar)
  const productTypeFilter = useReactiveVar(productTypeFilterVar)

  const sortBy = useReactiveVar(sortByVar)
  const [
    queryFilteredProducts,
    { loading: filteredLoading, data: filteredData, fetchMore },
  ] = useLazyQuery(GET_FILTERED_PRODUCTS, {
    notifyOnNetworkStatusChange: true,
  })

  const [lastProductID, setLastProductID] = useState(null)
  const [loadedCount, setLoadedCount] = useState(0)

  const [hasNextPage, setHasNextPage] = useState(false)

  const { locale } = useCategoryContext()

  const { data: productsMinMaxPriceStatic } = useQuery(
    GET_MIN_MAX_PRICE_STATIC,
    {
      variables: { language: locale, permalink: permalink },
    },
  )
  const {
    loading,
    error,
    data: productsMinMaxDimensions,
  } = useQuery(GET_MIN_MAX_DIMENSIONS_STATIC, {
    variables: { language: locale, permalink: permalink },
  })

  const minMaxPriceStatic = productsMinMaxPriceStatic
  const minMaxDimensionsStatic = productsMinMaxDimensions

  //* ======= clear all filters on permalink change
  // useEffect(() => {
  //   console.log('permalink (change) :>> ', permalink)
  //   // handleClearAllFilters()

  //   return
  // }, [permalink])

  // //====================================
  // //==== FILTER CLEARING FUNCTIONALITY!!!
  // useEffect(() => {
  //   const handleRouteChange = (url, { shallow }) => {
  //     // console.log('Handling Route change in categoruProductList')

  //     const isBackClicked = router?.query?.b
  //     // console.log('isBackClicked :>> ', isBackClicked)
  //     // console.log('router :>> ', router)

  //     if (isBackClicked) {
  //       //--- Scroll the additional positio
  //       if (sessionStorage.getItem('categoryPageScrollPosition')) {
  //         // Retrieve the saved scroll position from sessionStorage
  //         const scrollPosition = JSON.parse(
  //           sessionStorage.getItem('categoryPageScrollPosition'),
  //         )

  //         console.log('scrollPosition :>> ', scrollPosition)
  //         // Check if the scroll position is valid (e.g., a number)
  //         if (!isNaN(scrollPosition)) {
  //           // Scroll to the saved position
  //           window.scrollTo(0, scrollPosition)
  //         }
  //         // Optionally, you can remove the saved scroll position from sessionStorage
  //         // sessionStorage.removeItem("categoryPageScrollPosition");
  //       }
  //     } else {
  //       //--- Back was NOT CLICKED (clear filters)
  //       console.log('CLEARING ALL FILTERS')
  //       handleClearAllFilters()
  //     }
  //   }

  //   router.events.on('routeChangeComplete', handleRouteChange)

  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange)
  //   }
  // }, [router])

  //====================================
  //==== SCROLL BACK TO THE SAME PLACE
  //--- Save the category page positon
  useEffect(() => {
    // Save the scroll position when leaving the category page.
    const handleRouteChangeStart = () => {
      // console.log('beforeunload :>> triggered ')
      // console.log('window.scrollY :>> ', window.scrollY)

      sessionStorage.setItem(
        'categoryPageScrollPosition',
        window.scrollY.toString(),
      )
    }

    // Add a listener to the beforeunload event.
    // window.addEventListener('beforeunload', handleBeforeUnload)

    router.events.on('routeChangeStart', handleRouteChangeStart)

    // Remove the listener when the component unmounts.
    return () => {
      // window.removeEventListener('beforeunload', handleBeforeUnload)
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [])

  // useEffect(() => {
  //   // Store the scroll position when the component is unmounted (user navigates away).
  //   const handleBeforeUnload = () => {
  //     sessionStorage.setItem('scrollPosition', window.scrollY.toString())
  //   }

  //   // Restore the scroll position when the component is mounted (user returns to the page).
  //   const handleMount = () => {
  //     const savedScrollPosition = sessionStorage.getItem('scrollPosition')
  //     if (savedScrollPosition) {
  //       window.scrollTo(0, parseInt(savedScrollPosition, 10))
  //     }
  //   }

  //   // Add event listeners
  //   window.addEventListener('beforeunload', handleBeforeUnload)
  //   router.events.on('routeChangeComplete', handleMount)

  //   // Remove event listeners when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload)
  //     router.events.off('routeChangeComplete', handleMount)
  //   }
  // }, [])

  //* =============================================================================================================
  //* ========================================= DYNAMIC PAGINATION ================================================
  //* =============================================================================================================
  useEffect(() => {
    if (filteredData) {
      setLastProductID(
        filteredData.findManyProductContainer[
          filteredData?.findManyProductContainer.length - 1
        ]?.id,
      )
      setLoadedCount(filteredData?.findManyProductContainer.length)
    }

    if (
      filteredData?.findManyProductContainerCount ===
      filteredData?.findManyProductContainer.length
    ) {
      setHasNextPage(false)
    } else {
      setHasNextPage(true)
    }

    return
  }, [filteredData])

  //* =================================================================
  //* ==== getting constructed variable through helper functions ======
  //* =================================================================
  // =========== colors
  const colorOptions = getMainColorQueryVariable(colorsFilter)

  // =========== categories
  const categoriesOptions = getCategoriesQueryVariable(categoriesFilter)

  // =========== styles
  const stylesOptions = getStylesQueryVariable(stylesFilter)

  // =========== styles
  const productTypeOptions = getProductTypeQueryVariable(productTypeFilter)

  // =========== colors
  const frameColorOptions = getFrameColorQueryVariable(frameColorsFilter)

  // ========== dimensions
  const dimensionOptions = getDimensionsQueryVariable(
    widthFilter,
    heightFilter,
    lengthFilter,
  )

  //* ============================================================================================================================================
  //* =================== triggering useLazyQuery on change of any of the filter reactive variables or permalink change ==========================
  //* ============================================================================================================================================
  useEffect(() => {
    //! adding new filter component: add conditions here!
    if (
      colorOptions.length !== 0 ||
      categoriesOptions.length !== 0 ||
      stylesOptions.length !== 0 ||
      productTypeOptions.length !== 0 ||
      (priceFilter.minPrice != null && priceFilter.maxPrice != null) ||
      (widthFilter.minWidth != null && widthFilter.maxWidth != null) ||
      (heightFilter.minHeight != null && heightFilter.maxHeight != null) ||
      (lengthFilter.minLength != null && lengthFilter.maxLength != null) ||
      sortBy.queryVar !== null ||
      b2bToken?.b2b_only_selected_products === true ||
      b2bToken?.price_listId !== null
    ) {
      let customerFilter = {}
      let categoriesFilter = categoriesOptions
      if (b2bToken?.b2b_only_selected_products) {
        // customerFilter = {
        //   customers: { some: { customerId: { equals: b2bToken.id } } },
        // }
        if (categoriesFilter.length < 1 && categoryId)
          categoriesFilter = [
            {
              categories: {
                some: { id: { equals: categoryId } },
              },
            },
          ]

        customerFilter = {
          advanced_product: {
            is: {
              OR: [
                {
                  base_prices: {
                    some: {
                      price_listId: { equals: b2bToken.price_listId },
                    },
                  },
                },
                {
                  sofa_forms: {
                    some: {
                      form_price_fabric_category: {
                        some: {
                          price_listId: { equals: b2bToken.price_listId },
                        },
                      },
                    },
                  },
                },
                {
                  advanced_product_price_fabric_category: {
                    some: {
                      price_listId: { equals: b2bToken.price_listId },
                    },
                  },
                },
              ],
            },
          },
        }
      }

      queryFilteredProducts({
        variables: {
          customers: customerFilter,
          orderBy: sortBy.queryVar ?? { SVP: 'desc' },
          permalink,
          colors: colorOptions,
          frameColors: frameColorOptions,
          categories: categoriesFilter,
          styles: stylesOptions,
          productTypes: productTypeOptions,
          price:
            priceFilter.minPrice != null && priceFilter.maxPrice != null
              ? {
                  AND: [
                    { price_reference: { gte: priceFilter.minPrice } },
                    { price_reference: { lte: priceFilter.maxPrice } },
                  ],
                }
              : {},
          dimensions: dimensionOptions,
          cursor: undefined,
          language: router.locale,
        },
      })
    }

    return
  }, [
    categoriesFilter,
    stylesFilter,
    productTypeFilter,
    frameColorsFilter,
    colorsFilter,
    priceFilter,
    sortBy,
    permalink,
    widthFilter,
    heightFilter,
    lengthFilter,
    router.locale,
    b2bToken,
  ])

  //* ============================================================================================
  //* ============================ STATIC PAGINATION HANDLING (Deprecated) ====================================
  //* ============================================================================================
  // ==== ()NOT USED ANYMORE --- PASED TO NEXT/LINK
  const handleStaticPageRouting = (e, page) => {
    router.push(`/${pageSlug}/${permalink}/${page}`)
  }

  //* ================================================================================
  //* The result of this will be passed to a switch to define which compoent to render
  //* ================================================================================
  const handleCases = () => {
    // ==========================================================================================================================
    // If there is any filter OR the user is setting an orderBy OR the static price and the one in the reactive var are different
    // ==========================================================================================================================

    // console.log(
    //   'filteredData.findManyProductContainerCount :>> ',
    //   filteredData?.findManyProductContainerCount,
    // )
    if (b2bToken?.b2b_only_selected_products || b2bToken?.price_listId) {
      return 'DynamicData'
    }

    if (
      filteredData?.findManyProductContainerCount !== 0 &&
      (minMaxPriceStatic?.SingleProductMinPrice !== priceFilter.minPrice ||
        minMaxPriceStatic?.SingleProductMaxPrice !== priceFilter.maxPrice) &&
      (stylesFilter.length !== 0 ||
        categoriesFilter.length !== 0 ||
        productTypeFilter.length !== 0 ||
        colorsFilter.length !== 0 ||
        frameColorsFilter.length !== 0 ||
        priceFilter.minPrice ||
        priceFilter.maxPrice ||
        (widthFilter.minWidth && widthFilter.maxWidth) ||
        (heightFilter.minHeight && heightFilter.maxHeight) ||
        (lengthFilter.minLength && lengthFilter.maxLength) ||
        sortBy.queryVar !== null)
    ) {
      return 'DynamicData'
    } else if (
      filteredData?.findManyProductContainerCount === 0 &&
      (minMaxPriceStatic?.SingleProductMinPrice !== priceFilter.minPrice ||
        minMaxPriceStatic?.SingleProductMaxPrice !== priceFilter.maxPrice) &&
      (stylesFilter.length !== 0 ||
        categoriesFilter.length !== 0 ||
        productTypeFilter.length !== 0 ||
        colorsFilter.length !== 0 ||
        frameColorsFilter.length !== 0 ||
        priceFilter.minPrice ||
        priceFilter.maxPrice ||
        (widthFilter.minWidth && widthFilter.maxWidth) ||
        (heightFilter.minHeight && heightFilter.maxHeight) ||
        (lengthFilter.minLength && lengthFilter.maxLength) ||
        sortBy.queryVar !== null)
    ) {
      return 'NoFilteredProducts'
    } else {
      return 'StaticData'
    }
  }

  const getComponentFromSwitch = () => {
    switch (handleCases()) {
      case 'DynamicData':
        // console.log('showing dyamic daata :>> ')

        return DynamicData(
          filteredData,
          loadedCount,
          fetchMore,
          hasNextPage,
          classes,
          filteredLoading,
          permalink,
          productsMinMaxPriceStatic,
          productsMinMaxDimensions,
        )
      case 'StaticData':
        // console.log('showing StaticData daata :>> ')
        return StaticData(
          staticData,
          pageNumber,
          numberOfPages,
          handleStaticPageRouting,
          classes,
          productsCount,
          filteredLoading,
          permalink,
          productsMinMaxPriceStatic,
          productsMinMaxDimensions,
          locale,
          fullPermalink,
          pageSlug,
        )

      case 'NoFilteredProducts':
        return NoFilteredProducts(
          filteredData,
          lastProductID,
          fetchMore,
          hasNextPage,
          classes,
          filteredLoading,
          permalink,
          productsMinMaxPriceStatic,
          productsMinMaxDimensions,
        )
      default:
        throw new Error('Error in CategoryProductList switch...')
    }
  }

  //* ==============================================================
  //* Return whatever component matches the conditions in the switch
  //* ==============================================================
  return <>{getComponentFromSwitch()}</>
}

//* ==============================================================================
//* =========================== NoFilteredProducts ===========================
//* ==============================================================================
function NoFilteredProducts(
  filteredData,
  lastProductID,
  fetchMore,
  hasNextPage,
  classes,
  filteredLoading,
  permalink,
  productsMinMaxPriceStatic,
  productsMinMaxDimensions,
) {
  // console.log('filteredLoading :>> ', filteredLoading)
  return (
    <>
      <FilterComponent
        productsCount={filteredData?.findManyProductContainerCount ?? ''}
        productsLoading={filteredLoading}
        permalink={permalink}
        productsMinMaxPriceStatic={productsMinMaxPriceStatic}
        productsMinMaxDimensions={productsMinMaxDimensions}
      />
      {!filteredData && filteredLoading ? (
        <LinearProgress
          classes={{
            colorSecondary: classes.linearProgressColor,
            root: classes.linearProgress,
          }}
        />
      ) : (
        <>
          <div className={classes.root}>
            <NoDataFound image="/fallbacks/no-product-found.png" />
          </div>

          {filteredLoading && (
            <LinearProgress
              classes={{
                colorSecondary: classes.linearProgressColor,
                root: classes.linearProgress,
              }}
            />
          )}
        </>
      )}
    </>
  )
}

//* ==============================================================================
//* =========================== DYNAMIC DATA COMPONENT ===========================
//* ==============================================================================
function DynamicData(
  filteredData,
  loadedCount,
  fetchMore,
  hasNextPage,
  classes,
  filteredLoading,
  permalink,
  productsMinMaxPriceStatic,
  productsMinMaxDimensions,
) {
  // console.log('filteredLoading :>> ', filteredLoading)
  return (
    <>
      <FilterComponent
        productsCount={filteredData?.findManyProductContainerCount ?? ''}
        productsLoading={filteredLoading}
        permalink={permalink}
        productsMinMaxPriceStatic={productsMinMaxPriceStatic}
        productsMinMaxDimensions={productsMinMaxDimensions}
      />
      {!filteredData && filteredLoading ? (
        <LinearProgress
          classes={{
            colorSecondary: classes.linearProgressColor,
            root: classes.linearProgress,
          }}
        />
      ) : (
        <>
          <div className={classes.root}>
            <Grid container spacing={2}>
              {filteredData?.findManyProductContainer.map((product) => (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  key={product.id}
                  className={classes.gridItem}
                >
                  <DynamicCategoryProductCard productContainerID={product.id} />
                </Grid>
              ))}
            </Grid>

            {hasNextPage && (
              <>
                <LoadMoreButton
                  onClick={() => {
                    fetchMore({
                      variables: {
                        skip: loadedCount,
                      },
                    })
                  }}
                />
              </>
            )}
          </div>

          {filteredLoading && (
            <LinearProgress
              classes={{
                colorSecondary: classes.linearProgressColor,
                root: classes.linearProgress,
              }}
            />
          )}
        </>
      )}
    </>
  )
}

//* =================================================================================================
//* ================================== STATIC DATA COMPONENT ========================================
//* =================================================================================================
function StaticData(
  staticData,
  pageNumber,
  numberOfPages,
  handleStaticPageRouting, // Deprecated
  classes,
  productsCount,
  filteredLoading,
  permalink,
  productsMinMaxPriceStatic,
  productsMinMaxDimensions,
  locale,
  fullPermalink,
  pageSlug,
) {
  return (
    <>
      {staticData[0] ? (
        <>
          <FilterComponent
            productsCount={productsCount}
            productsLoading={filteredLoading}
            permalink={permalink}
            productsMinMaxPriceStatic={productsMinMaxPriceStatic}
            productsMinMaxDimensions={productsMinMaxDimensions}
          />
          {filteredLoading ? (
            <LinearProgress
              classes={{
                colorSecondary: classes.linearProgressColor,
                root: classes.linearProgress,
              }}
            />
          ) : (
            <div className={classes.root}>
              <Grid container spacing={2}>
                {staticData.map((productContainer) => (
                  <Grid
                    key={productContainer.id}
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <CategoryProductCard
                      productContainer={productContainer}
                      locale={locale}
                    />
                  </Grid>
                ))}
              </Grid>

              {/* ========================================================================================= */}
              {/* ==================================== STATIC PAGINATION ================================== */}
              {/* ========================================================================================= */}
              <Pagination
                page={pageNumber}
                count={numberOfPages}
                shape="rounded"
                size="medium"
                classes={{
                  root: classes.paginationRoot,
                }}
                renderItem={(item) => {
                  if (!item.disabled) {
                    return (
                      <Link
                        prefetch={false}
                        // href={{
                        //   pathname: `/${pageSlug}/${fullPermalink}/[slug]`,
                        //   query: {
                        //     slug: item.page,
                        //   },
                        // }}
                        href={`/${pageSlug}/${fullPermalink}/${item.page}`}
                        locale={locale}
                      >
                        <a>
                          <PaginationItem {...item} />
                        </a>
                      </Link>
                    )
                  } else {
                    return <PaginationItem {...item} />
                  }
                }}
              />
            </div>
          )}
        </>
      ) : (
        <NoDataFound image="/fallbacks/no-product-found.png" />
      )}
    </>
  )
}
