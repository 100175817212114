// Enum to describe the position of the armrests
export enum ArmrestsPosition {
  LEFT = 'left',
  RIGHT = 'right',
  BOTH = 'both',
  NONE = 'none',
}

export const getArmrestsPosition = (shapeType) => {
  //get last symbol of shape name
  const lastSymbol = shapeType.slice(-1)

  if (shapeType.includes('SOFA') || shapeType.includes('FOTEL')) {
    return ArmrestsPosition.BOTH
  }

  if (lastSymbol === 'L') {
    return ArmrestsPosition.LEFT
  } else if (lastSymbol === 'R') {
    return ArmrestsPosition.RIGHT
  }
  return ArmrestsPosition.NONE
}

export const setNewSize = (shape, armrestWidth) => {
  let armsCount = 0
  const armrestsPosition = getArmrestsPosition(shape.type)

  if (armrestsPosition === ArmrestsPosition.BOTH) {
    armsCount = 2
  } else if (
    armrestsPosition === ArmrestsPosition.LEFT ||
    armrestsPosition === ArmrestsPosition.RIGHT
  ) {
    armsCount = 1
  } else {
    armsCount = 0
  }

  const sizeWithoutArmrests = shape.originalDimension.width - armsCount * 16

  shape.dimensions.width = sizeWithoutArmrests + armsCount * armrestWidth
  shape.dimensions.armrest_width = armrestWidth
}
